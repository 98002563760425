export function applyHeaders(request, headers) {
  return {
    ...request,
    headers: {
      ...request.headers,
      ...headers
    }
  };
}

export function prepareToken(user) {
  return {
    userid: user && user.user_id ? user.user_id : null,
    authorization: user ? user.authorization_token : null
  };
}

export function applyToken(request, user) {
  return applyHeaders(request, prepareToken(user)); // ,
}

export function prepareQueryToken(user) {
  return `userid=${user ? user.user_id : ''}&authorization=${user ? user.authorization_token : ''}`;
}
