import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadUtilisateurs } from '../store/modules/utilisateurs';
import BaseLayout from './shared/BaseLayout';
import UtilisateursTableListView from '../components/tableaux/UtilisateursTableListView';
import { loadTranslations } from '../store/modules/translations';

const UtilisateursPage = ({ loadUtilisateurs, loadTranslations }) => {
  useEffect(() => {
    loadUtilisateurs();
    // load data
    loadTranslations();
  }, []);
  return (
    <BaseLayout>
      <div className="container">
        <div className="page-header">
          <div className="page-header-left">
            <div className="page-header-title">Utilisateurs</div>
          </div>
        </div>
        <UtilisateursTableListView />
      </div>
    </BaseLayout>
  );
};
UtilisateursPage.propTypes = {
  loadUtilisateurs: PropTypes.func.isRequired
};
export default connect(null, {
  loadUtilisateurs,
  loadTranslations
})(UtilisateursPage);
