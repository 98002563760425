import { CALL_API, Schemas } from '../Schemas';

import initialState from '../initialState';

const PURGE = 'cawa/PURGE';

export const settingsActionsHandlers = {
  [PURGE]: () => initialState
};

export function purge() {
  return {
    type: PURGE
  };
}
