import React, { Fragment, useState } from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { NavLink } from 'react-router-dom';
import { createPassword } from '../store/modules/auth';
import ModalSuccessCreatePassword from '../components/modals/ModalSuccessCreatePassword';

import { lowerCase } from 'lodash';
import { ROLES } from '../constants/Properties';

const CreatePasswordPage = ({ createPassword }) => {
  const { email, userid, token, role } = useParams();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    const data = values;
    data.authorization_token = token;
    data.reset_token = token;
    data.userId = Number(userid);

    if (data.password === data.password_repeat) {
      return createPassword(data)
        .then(() => {
          setError(null);
          setSuccess(true);
        })
        .catch(err => setError(err));
    } else {
      setError("Votre mot de passe n'est pas identique !");
    }
  };

  return (
    <Fragment>
      <div className="login">
        <div className="login-overflow">
          <div className="box animated">
            <div className="box-header">
              <h1>Définir mon mot de passe {role && lowerCase(ROLES[role])}</h1>
            </div>
            <div className="box-content">
              {success && <ModalSuccessCreatePassword />}

              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="email"
                      validate={required}
                      component="input"
                      className="field"
                      initialValue={(email && email) || ' '}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" disabled={true} />
                          <label>Email</label>
                        </div>
                      )}
                    </Field>

                    <Field validate={required} name="password" component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input
                            {...input}
                            type="password"
                            onChange={value => {
                              setError(null);
                              input.onChange(value);
                            }}
                          />
                          <label>Mot de passe *</label>
                        </div>
                      )}
                    </Field>
                    <Field
                      validate={required}
                      name="password_repeat"
                      component="input"
                      className="field"
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input
                            {...input}
                            type="password"
                            onChange={value => {
                              setError(null);
                              input.onChange(value);
                            }}
                          />
                          <label>Confirmer votre mot de passe *</label>
                        </div>
                      )}
                    </Field>

                    {error && <div className="error-message">{error}</div>}

                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                    <NavLink className="btn-link" to="/login">
                      Revenir à la page de connexion
                    </NavLink>
                  </form>
                )}
              />
            </div>
          </div>
          <div className="login-aside">
            <img className="logo" src={require(`../../assets/images/logo-coastappli.svg`)} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CreatePasswordPage.propTypes = {
  createPassword: PropTypes.func.isRequired
};

export default connect(null, { createPassword })(CreatePasswordPage);
