import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_OBSERVATIONS_REQUEST = 'cawa/FETCH_ALL_OBSERVATIONS_REQUEST';
const FETCH_ALL_OBSERVATIONS_SUCCESS = 'cawa/FETCH_ALL_OBSERVATIONS_SUCCESS';
const FETCH_ALL_OBSERVATIONS_FAILURE = 'cawa/FETCH_ALL_OBSERVATIONS_FAILURE';

const CREATE_OBSERVATION_REQUEST = 'cawa/CREATE_OBSERVATION_REQUEST';
const CREATE_OBSERVATION_SUCCESS = 'cawa/CREATE_OBSERVATION_SUCCESS';
const CREATE_OBSERVATION_FAILURE = 'cawa/CREATE_OBSERVATION_FAILURE';

const DELETE_OBSERVATION_REQUEST = 'cawa/DELETE_OBSERVATION_REQUEST';
const DELETE_OBSERVATION_SUCCESS = 'cawa/DELETE_OBSERVATION_SUCCESS';
const DELETE_OBSERVATION_FAILURE = 'cawa/DELETE_OBSERVATION_FAILURE';

const UPDATE_OBSERVATION_REQUEST = 'cawa/UPDATE_OBSERVATION_REQUEST';
const UPDATE_OBSERVATION_SUCCESS = 'cawa/UPDATE_OBSERVATION_SUCCESS';
const UPDATE_OBSERVATION_FAILURE = 'cawa/UPDATE_OBSERVATION_FAILURE';

export const observationsActionsHandlers = {
  [FETCH_ALL_OBSERVATIONS_REQUEST]: state =>
    flow(set('loaded.observations', false), set('loading.observations', true))(state),
  [FETCH_ALL_OBSERVATIONS_SUCCESS]: (state, action) =>
    flow(
      set('entities.observations', action.response.entities.observations || {}),
      set('loaded.observations', true),
      set('loading.observations', false)
    )(state),
  [FETCH_ALL_OBSERVATIONS_FAILURE]: state =>
    flow(set('loaded.observations', false), set('loading.observations', false))(state),

  [CREATE_OBSERVATION_SUCCESS]: (state, action) => {
    return flow(
      set('entities.observations', {
        ...state.entities.observations,
        ...action.response.entities.observations
      }),
      set('loaded.observations', true),
      set('loading.observations', false)
    )(state);
  },

  [UPDATE_OBSERVATION_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.observations.${action.observation.observation_id}`, action.response),
      set('loaded.observations', true),
      set('loading.observations', false)
    )(state);
  },

  [DELETE_OBSERVATION_SUCCESS]: (state, action) =>
    flow(set('entities.observations', omit(state.entities.observations, action.protocoleId)))(state)
};

export function createObservation(data) {
  return {
    [CALL_API]: {
      types: [CREATE_OBSERVATION_REQUEST, CREATE_OBSERVATION_SUCCESS, CREATE_OBSERVATION_FAILURE],
      method: 'POST',
      endpoint: '/observations',
      schema: Schemas.OBSERVATION,
      body: data
    }
  };
}

export function loadObservations() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_OBSERVATIONS_REQUEST,
        FETCH_ALL_OBSERVATIONS_SUCCESS,
        FETCH_ALL_OBSERVATIONS_FAILURE
      ],
      method: 'GET',
      endpoint: '/observations',
      schema: Schemas.OBSERVATION_ARRAY,
      successMessage: 'Observations chargés avec succès'
    }
  };
}

export function deleteObservation(observationId) {
  return {
    observationId,
    [CALL_API]: {
      types: [DELETE_OBSERVATION_REQUEST, DELETE_OBSERVATION_SUCCESS, DELETE_OBSERVATION_FAILURE],
      method: 'DELETE',
      endpoint: `/observations/${observationId}`,
      successMessage: "L'observation a bien été supprimée"
    }
  };
}

export function updateObservation(observation, data) {
  return {
    observation,
    [CALL_API]: {
      types: [UPDATE_OBSERVATION_REQUEST, UPDATE_OBSERVATION_SUCCESS, UPDATE_OBSERVATION_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/observations/${observation.observation_id}`,
      successMessage: "L'observation a bien été modifiée"
    }
  };
}
