import React, { Fragment, useState } from 'react';

import { map } from 'lodash';

import Modal from 'react-modal';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalDisplayExif = ({ exif }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <button onClick={() => setIsOpen(!isOpen)} className="btn btn-primary">
        <span>Données EXIF</span>
      </button>
      <Modal {...modalDefaultClass} id="history-alertes" isOpen={isOpen}>
        <div className="modal-title">
          Données EXIF
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <div className="info-table">
            {map(exif, (value, key) => (
              <div key={key} className="info-cell">
                <label>{key}</label>
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ModalDisplayExif;
