import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateProtocole } from '../../store/modules/protocoles';

import Select from 'react-select';

import { Form, Field } from 'react-final-form';
import { find, map, findIndex, cloneDeep, isUndefined, filter, isEmpty } from 'lodash';

import Modal from 'react-modal';

import { TYPES_SAISIE } from '../../constants/Properties';

const ModalFormMesure = ({ indexMesure, protocole, mesure, editMode, updateProtocole }) => {
  const optionsTypesSaisie = map(TYPES_SAISIE, (item, key) => {
    return { value: key, label: item };
  });

  const optionsIsObligatoire = [
    {
      value: true,
      label: 'Oui'
    },
    {
      value: false,
      label: 'Non'
    }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredTypeSaisie = value => (!isEmpty(value) ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    let data = values;

    if (!editMode && find(protocole.mesures, m => m.nom === data.nom)) {
      setError('Une mesure existe déja avec ce nom !');
    } else {
      if (editMode) {
        if (data.nom === protocole.mesures[indexMesure].nom) {
          const dataProtocole = cloneDeep(protocole);
          dataProtocole.mesures[findIndex(protocole.mesures, m => m.nom === mesure.nom)] = data;

          return updateProtocole(protocole, { mesures: [...dataProtocole.mesures] })
            .then(() => {
              setIsOpen(false);
            })
            .catch(err => setError(err));
        }

        if (
          filter(
            protocole.mesures,
            mesure => mesure.nom === protocole.mesures[indexMesure].nom && mesure.nom === data.nom
          ).length > 0
        ) {
          setError('Une mesure existe déja avec ce nom !');
        } else {
          const dataProtocole = cloneDeep(protocole);
          dataProtocole.mesures[findIndex(protocole.mesures, m => m.nom === mesure.nom)] = data;

          updateProtocole(protocole, { mesures: [...dataProtocole.mesures] })
            .then(() => setIsOpen(false))
            .catch(err => setError(err));
        }
      } else {
        data.mesures = isUndefined(protocole.mesures)
          ? [
              {
                nom: data.nom,
                type_saisie: data.type_saisie,
                obligatoire: data.obligatoire
              }
            ]
          : [
              ...protocole.mesures,
              {
                nom: data.nom,
                type_saisie: data.type_saisie,
                obligatoire: data.obligatoire
              }
            ];

        updateProtocole(protocole, { mesures: [...data.mesures] })
          .then(() => setIsOpen(false))
          .catch(err => setError(err));
      }
    }
  };

  return (
    <Fragment>
      {!editMode ? (
        <button
          onClick={() => {
            setError(null);
            setIsOpen(!isOpen);
          }}
          className="btn btn-primary"
        >
          <i className="fas fa-plus-circle" />
          <span>Ajouter une mesure</span>
        </button>
      ) : (
        <button className="btn" onClick={() => setIsOpen(!isOpen)}>
          <i className="fas fa-pencil" />
        </button>
      )}

      <Modal
        className="modal-content medium-modal"
        overlayClassName="modal-overlay"
        id="modal-protocole"
        isOpen={isOpen}
      >
        <div className="modal-title">
          {!editMode ? 'Ajouter une mesure' : 'Modifier une mesure'}
          <div
            onClick={() => {
              setIsOpen(false);
              setError(null);
            }}
            className="btn-close"
          >
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  initialValue={(editMode && mesure && mesure.nom) || ''}
                  validate={required}
                  name="nom"
                  component="input"
                  className="field"
                  parse={value => value}
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Nom *</label>
                    </div>
                  )}
                </Field>

                <Field
                  name="type_saisie"
                  validate={requiredTypeSaisie}
                  initialValue={editMode && mesure.type_saisie}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          onChange={typesSaisies => {
                            input.onChange(map(typesSaisies, v => v.value));
                          }}
                          placeholder="Sélectionner un/ou des types de saisies"
                          isMulti
                          name="type_saisie"
                          defaultValue={
                            (editMode &&
                              map(mesure.type_saisie, type => {
                                return {
                                  label: find(optionsTypesSaisie, o => o.value === type).label,
                                  value: type
                                };
                              })) ||
                            []
                          }
                          options={optionsTypesSaisie}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <label>Type de saisie autorisée *</label>
                      </div>
                    );
                  }}
                </Field>

                <Field
                  initialValue={
                    editMode ? (mesure && mesure.obligatoire === true ? true : false) : true
                  }
                  name="obligatoire"
                  component="select"
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <Select
                          options={optionsIsObligatoire}
                          placeholder="Sélectionner un type"
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsIsObligatoire, o => o.value === input.value)}
                        />
                        <label>Obligatoire *</label>
                      </div>
                    );
                  }}
                </Field>

                {error && <div className="error-message">{error}</div>}
                <div className="modal-footer">
                  <div className="btn-group">
                    <button
                      onClick={() => {
                        setError(null);
                        setIsOpen(false);
                      }}
                      className="btn btn-secondary"
                    >
                      <span>Annuler</span>
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormMesure.propTypes = {
  updateProtocole: PropTypes.func.isRequired,
  protocole: PropTypes.object.isRequired
};

export default connect(null, {
  updateProtocole
})(ModalFormMesure);
