import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { recoverPassword } from '../../store/modules/auth';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import ModalSuccessResetPasswordSend from '../../components/modals/ModalSuccessResetPasswordSend';

const PasswordOubliePage = ({ recoverPassword }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    return recoverPassword(values.email)
      .then(() => {
        setError(null);
        setSuccess(true);
      })
      .catch(err => {
        setError(err);
        console.error('PasswordOubliePage erreur', err);
      });
  };

  return (
    <Fragment>
      <div className="login">
        <div className="login-overflow">
          <div className="box animated">
            <div className="box-header">
              <h1>Réinitialisez votre mot de passe</h1>
            </div>
            <div className="box-content">
              {success && <ModalSuccessResetPasswordSend />}
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="email" validate={required} component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Email</label>
                        </div>
                      )}
                    </Field>
                    {error && <div>{error}</div>}
                    <button type="submit" className="btn btn-primary">
                      Valider
                    </button>

                    <NavLink className="btn-link" to="/login">
                      Revenir à la page de connexion
                    </NavLink>
                  </form>
                )}
              />
            </div>
          </div>
          <div className="login-aside">
            <img className="logo" src={require(`../../../assets/images/logo-coastappli.svg`)} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PasswordOubliePage.propTypes = {
  recoverPassword: PropTypes.func.isRequired
};

export default connect(null, { recoverPassword })(PasswordOubliePage);
