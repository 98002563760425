import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_SITES_REQUEST = 'cawa/FETCH_ALL_SITES_REQUEST';
const FETCH_ALL_SITES_SUCCESS = 'cawa/FETCH_ALL_SITES_SUCCESS';
const FETCH_ALL_SITES_FAILURE = 'cawa/FETCH_ALL_SITES_FAILURE';

const CREATE_SITE_REQUEST = 'cawa/CREATE_SITE_REQUEST';
const CREATE_SITE_SUCCESS = 'cawa/CREATE_SITE_SUCCESS';
const CREATE_SITE_FAILURE = 'cawa/CREATE_SITE_FAILURE';

const DELETE_SITE_REQUEST = 'cawa/DELETE_SITE_REQUEST';
const DELETE_SITE_SUCCESS = 'cawa/DELETE_SITE_SUCCESS';
const DELETE_SITE_FAILURE = 'cawa/DELETE_SITE_FAILURE';

const UPDATE_SITE_REQUEST = 'cawa/UPDATE_SITE_REQUEST';
const UPDATE_SITE_SUCCESS = 'cawa/UPDATE_SITE_SUCCESS';
const UPDATE_SITE_FAILURE = 'cawa/UPDATE_SITE_FAILURE';

const ADD_PROTOCOLE_SITE_REQUEST = 'cawa/ADD_PROTOCOLE_SITE_REQUEST';
const ADD_PROTOCOLE_SITE_SUCCESS = 'cawa/ADD_PROTOCOLE_SITE_SUCCESS';
const ADD_PROTOCOLE_SITE_FAILURE = 'cawa/ADD_PROTOCOLE_SITE_FAILURE';

const UPDATE_PROTOCOLE_SITE_REQUEST = 'cawa/UPDATE_PROTOCOLE_SITE_REQUEST';
const UPDATE_PROTOCOLE_SITE_SUCCESS = 'cawa/UPDATE_PROTOCOLE_SITE_SUCCESS';
const UPDATE_PROTOCOLE_SITE_FAILURE = 'cawa/UPDATE_PROTOCOLE_SITE_FAILURE';

export const sitesActionsHandlers = {
  [FETCH_ALL_SITES_REQUEST]: state =>
    flow(set('loaded.sites', false), set('loading.sites', true))(state),
  [FETCH_ALL_SITES_SUCCESS]: (state, action) =>
    flow(
      set('entities.sites', action.response.entities.sites || {}),
      set('loaded.sites', true),
      set('loading.sites', false)
    )(state),
  [FETCH_ALL_SITES_FAILURE]: state =>
    flow(set('loaded.sites', false), set('loading.sites', false))(state),

  [CREATE_SITE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.sites', {
        ...state.entities.sites,
        ...action.response.entities.sites
      }),
      set('loaded.sites', true),
      set('loading.sites', false)
    )(state);
  },

  [UPDATE_SITE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.sites.${action.site.site_id}`, { ...action.site, ...action.response }),
      set('loaded.sites', true),
      set('loading.sites', false)
    )(state);
  },
  [ADD_PROTOCOLE_SITE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.sites.${action.site.site_id}`, ...action.response),
      set('loaded.sites', true),
      set('loading.sites', false)
    )(state);
  },
  [DELETE_SITE_SUCCESS]: (state, action) =>
    flow(set('entities.sites', omit(state.entities.sites, action.siteId)))(state)
};

export function createSite(data) {
  return {
    [CALL_API]: {
      types: [CREATE_SITE_REQUEST, CREATE_SITE_SUCCESS, CREATE_SITE_FAILURE],
      method: 'POST',
      endpoint: '/sites',
      schema: Schemas.SITE,
      body: data
    }
  };
}

export function loadSites() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_SITES_REQUEST, FETCH_ALL_SITES_SUCCESS, FETCH_ALL_SITES_FAILURE],
      method: 'GET',
      endpoint: '/sites',
      schema: Schemas.SITE_ARRAY,
      successMessage: 'Sites chargés avec succès'
    }
  };
}

export function deleteSite(siteId) {
  return {
    siteId,
    [CALL_API]: {
      types: [DELETE_SITE_REQUEST, DELETE_SITE_SUCCESS, DELETE_SITE_FAILURE],
      method: 'DELETE',
      endpoint: `/sites/${siteId}`,
      successMessage: 'Le site a bien été supprimée'
    }
  };
}

export function updateSite(site, data) {
  return {
    site,
    [CALL_API]: {
      types: [UPDATE_SITE_REQUEST, UPDATE_SITE_SUCCESS, UPDATE_SITE_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/sites/${site.site_id}`,
      successMessage: 'Le site a bien été modifié'
    }
  };
}
export function addProtocoleSite(site, data) {
  return {
    site,
    [CALL_API]: {
      types: [ADD_PROTOCOLE_SITE_REQUEST, ADD_PROTOCOLE_SITE_SUCCESS, ADD_PROTOCOLE_SITE_FAILURE],
      method: 'POST',
      body: data,
      endpoint: `/sites/${site.site_id}/addProtocole`,
      successMessage: "Ajout d'un protocole sur site réussi"
    }
  };
}
export function updateProtocoleSite(site, data) {
  return {
    site,
    [CALL_API]: {
      types: [
        UPDATE_PROTOCOLE_SITE_REQUEST,
        UPDATE_PROTOCOLE_SITE_SUCCESS,
        UPDATE_PROTOCOLE_SITE_FAILURE
      ],
      method: 'PUT',
      body: data,
      endpoint: `/sites/${site.site_id}/updateProtocoleSite`,
      successMessage: 'Mise à jour du protocole du site réussi'
    }
  };
}
