import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalConfirmDelete = ({ actionDelete, openModal, closeModal }) => {
  const [error, setError] = useState(null);

  const confirmDelete = () => {
    return actionDelete()
      .then(() => {
        closeModal();
      })
      .catch(err => setError(err));
  };
  return (
    <Modal {...modalDefaultClass} id="confirm-delete" isOpen={openModal}>
      <div className="modal-title">
        Suppression
        <div
          onClick={() => {
            closeModal();
            setError(null);
          }}
          className="btn-close"
        >
          <i className="far fa-times" />
        </div>
      </div>
      <div className="modal-body">Êtes-vous sûr de vouloir supprimer cet élément ?</div>
      {error && <div className="error-message">{error}</div>}
      <div className="modal-footer">
        <div className="btn-group">
          <button
            onClick={() => {
              closeModal();
              setError(null);
            }}
            className="btn btn-secondary"
          >
            <span>Annuler</span>
          </button>
          <button className="btn btn-primary" onClick={() => confirmDelete()}>
            <span>Supprimer</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

ModalConfirmDelete.propTypes = {
  actionDelete: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ModalConfirmDelete;
