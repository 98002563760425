import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalConfirmDelete from '../modals/ModalConfirmDelete';
import ModalFormEditAdmin from '../modals/ModalFormEditAdmin';

const AdminCard = ({ admin, deleteUser }) => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  return (
    <div className="col">
      <div className="box card">
        <div className="box-content">
          <div className="card-infos">
            <div className="card-title">
              {admin.prenom} {admin.nom}
            </div>
            <div className="card-data">{admin.email}</div>
          </div>
          <div className="card-actions">
            <div className="btn-group">
              <ModalFormEditAdmin admin={admin} />
              <button className="btn" onClick={() => setOpenModalDelete(true)}>
                <i className="fas fa-trash-alt" />
              </button>
              <ModalConfirmDelete
                openModal={openModalDelete}
                closeModal={() => setOpenModalDelete(false)}
                actionDelete={() => deleteUser()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminCard.propTypes = {
  admin: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired
};
export default AdminCard;
