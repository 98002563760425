import React from 'react';
import PropTypes from 'prop-types';

const FilterSearch = ({ updateSearchFilter, searchFilter }) => {
  return (
    <div className="field search">
      <input
        onChange={e => updateSearchFilter(e.target.value)}
        placeholder="Rechercher"
        value={searchFilter || ''}
      />
    </div>
  );
};

FilterSearch.propTypes = {
  updateSearchFilter: PropTypes.func.isRequired
};

export default FilterSearch;
