import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseLayout from './shared/BaseLayout';
import ButtonsSelector from '../components/shared/ButtonsSelector';

import HeaderTableau from '../components/shared/HeaderTableau';
import SiteProtocolesTableListView from '../components/tableaux/SiteProtocolesTableListView';
import SiteObservationsTableListView from '../components/tableaux/SiteObservationsTableListView';

import { updateSite } from '../store/modules/sites';

const SitePage = ({ user, users, site, protocoles, updateSite }) => {
  useEffect(() => {}, []);
  const [selectedMenu, setSelectedMenu] = useState('protocoles');

  const renderView = () => {
    switch (selectedMenu) {
      case 'protocoles':
        return (
          <SiteProtocolesTableListView
            onClickDelete={params => updateSite(site, { protocoles: params })}
            site={site || {}}
          />
        );
      case 'observations':
        return <SiteObservationsTableListView users={users} protocoles={protocoles} site={site} />;
      default:
        break;
    }
  };
  return (
    <BaseLayout contentClass="column">
      <Fragment>
        <HeaderTableau name={site && site.nom} />
        <div className="content-tabs">
          <div className="container">
            <ButtonsSelector
              selected={selectedMenu}
              onClickButton={value => setSelectedMenu(value)}
              buttons={[
                {
                  label: 'Protocoles',
                  value: 'protocoles'
                },
                {
                  label: 'Contributions',
                  value: 'observations'
                }
              ]}
            />
          </div>
        </div>
        <div className="container">{renderView()}</div>
      </Fragment>
    </BaseLayout>
  );
};

SitePage.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { siteId }
    }
  } = props;

  return {
    siteId: Number(siteId),
    user: state.auth.user,
    users: state.data.entities.utilisateurs,
    site: state.data.entities.sites[siteId],
    protocoles: state.data.entities.protocoles
  };
};
export default connect(mapStateToProps, { updateSite })(SitePage);
