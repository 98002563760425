import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { updateProtocole } from '../../store/modules/protocoles';

import TableListView from '../shared/TableListView';
import ModalFormMesure from '../modals/ModalFormMesure';
import ButtonConfirmModal from '../shared/ButtonConfirmModal';

import { filter, values, cloneDeep, map } from 'lodash';

const MesuresProtocoleTable = ({ protocole, updateProtocole }) => {
  const deleteMesure = nom => {
    const data = cloneDeep(protocole);

    data.mesures = filter(protocole.mesures, p => p.nom !== nom);

    return updateProtocole(protocole, { mesures: data.mesures });
  };
  return (
    <TableListView
      buttons={() => <ModalFormMesure protocole={protocole} />}
      className="table-wrapper"
      data={values(protocole.mesures)}
      search={['nom', 'type_saisie']}
      sortByDefault="nom"
      searchLabel={'Rechercher'}
      sortOrderDefault="asc"
      cols={() => ({
        nom: {
          label: 'Nom'
        },
        type_saisie: {
          label: 'Type saisie',
          render: typesSaisie =>
            map(typesSaisie, type => (
              <div className="badge" key={type}>
                {type}
              </div>
            ))
        },
        obligatoire: { label: 'Obligatoire', value: value => (value === true ? 'Oui' : 'Non') }
      })}
      rowButtons={(mesure, row) => (
        <Fragment>
          {mesure && (
            <div className="btn-group" key={row.nom}>
              <ModalFormMesure indexMesure={row} protocole={protocole} mesure={mesure} editMode />

              <ButtonConfirmModal
                message="Voulez-vous vraiment supprimer cet mesure ?"
                actionDelete={() => deleteMesure(mesure.nom)}
              />
            </div>
          )}
        </Fragment>
      )}
    />
  );
};

MesuresProtocoleTable.propTypes = {
  updateProtocole: PropTypes.func.isRequired,
  protocole: PropTypes.object.isRequired
};

export default connect(null, {
  updateProtocole
})(MesuresProtocoleTable);
