import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';

const ButtonsSelector = ({ buttons, onClickButton, selected }) => {
  return (
    <div className="toggle">
      {map(buttons, (button, index) => (
        <button
          onClick={() => onClickButton(button.value)}
          key={index}
          className={`btn ${selected === button.value ? 'active' : ''}`}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

ButtonsSelector.propTypes = {
  selected: PropTypes.string.isRequired,
  buttons: PropTypes.array.isRequired,
  onClickButton: PropTypes.func.isRequired
};
export default ButtonsSelector;
