import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { map, filter, toLower } from 'lodash';
import { Form, Field } from 'react-final-form';
import removeAccents from 'remove-accents';

import { validateEmail } from '../utils/StringValidator';

import Modal from 'react-modal';

import FilterSearch from '../components/shared/FilterSearch';

import modalDefaultClass from '../utils/ModalDefaultClass';

import BaseLayout from './shared/BaseLayout';

import AdminCard from '../components/cards/AdminCard';

import { loadAdministrateurs, createAdmin, deleteUtilisateur } from '../store/modules/utilisateurs';

const AdministrateursPage = ({
  administrateurs,
  loadAdministrateurs,
  createAdmin,
  deleteUtilisateur
}) => {
  useEffect(() => {
    loadAdministrateurs();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [errorAddAdmin, setErrorAddAdmin] = useState(null);
  const [filterAdmin, setFilterAdmin] = useState('');

  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    return createAdmin(values)
      .then(() => setIsOpen(false))
      .catch(err => setErrorAddAdmin(err));
  };

  return (
    <BaseLayout>
      <Fragment>
        <div className="container">
          <div className="page-header">
            <div className="page-header-left">
              <div className="page-header-title">Administrateurs</div>
            </div>
            <div className="page-header-toolbar">
              <div className="page-header-toolbar-left">
                <FilterSearch
                  searchFilter={filterAdmin}
                  updateSearchFilter={value => setFilterAdmin(value)}
                />
              </div>
              <div className="page-header-toolbar-right">
                <button onClick={() => setIsOpen(!isOpen)} className="btn btn-primary">
                  <i className="fas fa-plus-circle" />
                  <span>Ajouter un administrateur</span>
                </button>

                <Modal {...modalDefaultClass} id="history-alertes" isOpen={isOpen}>
                  <div className="modal-title">
                    Ajouter un administrateur
                    <div onClick={() => setIsOpen(false)} className="btn-close">
                      <i className="far fa-times" />
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form
                      onSubmit={handleSubmit}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Field
                            validate={required}
                            name="prenom"
                            component="input"
                            className="field"
                          >
                            {({ input, meta }) => (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <input {...input} type="text" />
                                <label>Prénom *</label>
                              </div>
                            )}
                          </Field>
                          <Field validate={required} name="nom" component="input" className="field">
                            {({ input, meta }) => (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <input {...input} type="text" />
                                <label>Nom *</label>
                              </div>
                            )}
                          </Field>
                          <Field
                            validate={requiredEmail}
                            name="email"
                            component="input"
                            className="field"
                          >
                            {({ input, meta }) => (
                              <div className="field">
                                {meta.error && meta.touched && (
                                  <div className="field-error">{meta.error}</div>
                                )}
                                <input {...input} type="text" />
                                <label>Adresse e-mail *</label>
                              </div>
                            )}
                          </Field>

                          {errorAddAdmin && <div className="error-message">{errorAddAdmin}</div>}
                          <div className="modal-footer">
                            <div className="btn-group">
                              <button
                                onClick={() => setIsOpen(false)}
                                className="btn btn-secondary"
                              >
                                <span>Annuler</span>
                              </button>
                              <button type="submit" className="btn btn-primary">
                                <span>Valider</span>
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    />
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          <div className="page-content">
            <div className="grid-3_xs-1_sm-1_md-2">
              {map(
                filterAdmin
                  ? filter(administrateurs, o => {
                      return (
                        removeAccents(toLower(o.nom)).includes(
                          removeAccents(toLower(filterAdmin))
                        ) ||
                        removeAccents(toLower(o.email)).includes(
                          removeAccents(toLower(filterAdmin))
                        ) ||
                        removeAccents(toLower(o.prenom)).includes(
                          removeAccents(toLower(filterAdmin))
                        )
                      );
                    })
                  : administrateurs,
                admin => (
                  <AdminCard
                    key={admin.user_id}
                    admin={admin}
                    deleteUser={() => deleteUtilisateur(admin.user_id)}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </BaseLayout>
  );
};

AdministrateursPage.propTypes = {
  loadAdministrateurs: PropTypes.func.isRequired,
  createAdmin: PropTypes.func.isRequired,
  deleteUtilisateur: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  administrateurs: state.data.entities.utilisateurs
});

export default connect(mapStateToProps, {
  loadAdministrateurs,
  createAdmin,
  deleteUtilisateur
})(AdministrateursPage);
