import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { map, filter, get } from 'lodash';

import TableListView from '../shared/TableListView';

import { TYPES_ETALONS, TYPES_PROFILS, TYPES_SAISIE } from '../../constants/Properties';
import { API_URL } from '../../constants/Config';
import ModalDisplayExif from '../modals/ModalDisplayExif';

const SiteObservationsTableListView = ({ users, protocoles, site, observations }) => {
  const headerCSV = [
    { label: 'Date & Heure', key: 'date_heure' },
    { label: 'Utilisateur', key: 'user_id' },
    { label: 'Profil', key: 'profil' },
    { label: 'Nom du site', key: 'nom_site' },
    { label: 'Protocole', key: 'protocole_id' },
    { label: 'Numéro de Transect', key: 'numero_transect' },
    { label: 'Latitude', key: 'latitude' },
    { label: 'Longitude', key: 'longitude' },
    { label: 'Remarque', key: 'remarque' },
    { label: 'Mesures', key: 'mesures' },
    { label: 'Photos', key: 'photos' }
  ];

  return (
    <Fragment>
      <TableListView
        className="table-wrapper"
        data={filter(observations, id => id.site_id === site.site_id)}
        search={['user_id', 'profil', 'protocole_id']}
        sortOrderDefault="desc"
        sortByDefault="date_heure"
        searchLabel={'Rechercher'}
        buttons={data => {
          return (
            <CSVLink
              className="btn btn-primary"
              data={map(data, d => ({
                ...d,
                date_heure: moment(d.date_heure).format('DD/MM/YYYY HH:mm'),
                user_id: `${get(users, `${d.user_id}.nom`)}`,
                profil: get(TYPES_PROFILS, get(users, `${d.user_id}.profil`)),
                protocole_id: get(protocoles, `${d.protocole_id}.nom`),
                nom_site: site.nom,
                latitude: site.latitude,
                longitude: site.longitude,
                mesures: map(
                  d.mesures,
                  mesure =>
                    `Nom:${mesure.nom},Saisie:${TYPES_SAISIE[mesure.typeMesure]},Valeur:${
                      mesure.value
                    }${(mesure.mesure_etalon_repetition && ",Type d'étalon:") ||
                      ''} ${(mesure.mesure_etalon_repetition &&
                      TYPES_ETALONS[mesure.mesure_etalon_repetition.type]) ||
                      ''}`
                ),
                photos: map(
                  d.photos,
                  p => `Fichier: ${p.fileName} , Exif:${JSON.stringify(p.exif)}`
                )
              }))}
              filename={`contributions_${site.nom}.csv`}
              separator=";"
              headers={headerCSV}
            >
              <i className="fas fa-plus-circle" />
              <span>Exporter les données en CSV</span>
            </CSVLink>
          );
        }}
        cols={() => ({
          date_heure: { label: 'Date', render: value => moment(value).format('DD/MM/YYYY HH:mm') },
          user_id: {
            label: 'Utilisateur',
            value: value => {
              return users[value].nom;
            }
          },
          profil: {
            label: 'Profil',
            value: (value, row) => TYPES_PROFILS[users[row.user_id].profil]
          },
          nom_site: {
            label: 'Nom site',
            value: (value, row) => site.nom
          },
          protocole_id: {
            label: 'Protocole',
            value: value => protocoles[value].nom
          },
          numero_transect: { label: 'N° de Transect' },
          latitude: { label: 'Latitude', value: (value, row) => site.latitude },
          longitude: { label: 'Longitude', value: (value, row) => site.longitude },
          remarque: { label: 'Remarque' },
          mesures: {
            label: 'Mesures',
            render: (value, row) =>
              map(value, (mesure, index) => {
                return (
                  <div key={index}>
                    <p>{mesure.nom}</p>
                    <p>{mesure.value} cm</p>
                    <p>Saisie {TYPES_SAISIE[mesure.typeMesure]}</p>
                    {mesure.mesure_etalon_repetition && (
                      <p>Type d'étalon : {TYPES_ETALONS[mesure.mesure_etalon_repetition.type]}</p>
                    )}
                  </div>
                );
              })
          },
          photos: {
            label: 'Photos',
            render: (value, row) =>
              map(value, (photo, index) => {
                return (
                  <div key={index}>
                    <a
                      target="_blank"
                      href={`${API_URL}/uploads/${(photo.fileName && photo.fileName) || ''}`}
                    >
                      Photo {index + 1}
                    </a>
                  </div>
                );
              })
          }
        })}
      />
    </Fragment>
  );
};

SiteObservationsTableListView.propTypes = {
  protocoles: PropTypes.object.isRequired,
  site: PropTypes.object,
  users: PropTypes.object
};

export default connect(state => ({
  observations: state.data.entities.observations
}))(SiteObservationsTableListView);
