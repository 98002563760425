import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { get, isString, isArray, isFunction, map, orderBy, upperCase, values } from 'lodash';
import { InputField, SelectField } from 'react-components-linaia';

import sharedSelector from '../../selectors/SharedSelector';

const TableListView = ({
  title,
  className,
  data,
  cols,
  buttons,
  rowButtons,
  onClickRow,
  hideTableHeader,
  search,
  filters,
  searchLabel = 'Rechercher',
  sortOptions,
  sortByDefault,
  sortOrderDefault = 'asc',
  sortLabel
}) => {
  const [searchText, setSearchText] = useState();
  const [searchedData, setSearchedData] = useState(data);

  const [sort, setSort] = useState({
    by: sortByDefault,
    order: sortOrderDefault
  });

  useEffect(() => {
    const orderByData = dataRows => {
      return orderBy(
        dataRows,
        entity => {
          // Order by the result of value() function in cols object if exists
          const value = get(entity, sort.by);
          const colValue = get(cols(), `${sort.by}.value`);
          if (colValue) {
            return upperCase(colValue(value, entity));
          }
          return upperCase(value);
        },
        sort.order
      );
    };

    if (isString(search) || isArray(search)) {
      return setSearchedData(orderByData(sharedSelector(data, searchText, search, cols())));
    }
    return setSearchedData(orderByData(data));
  }, [sort, data, searchText, search]);

  const setOrder = by =>
    setSort({
      by,
      order: sort.by !== by || sort.order !== 'asc' ? 'asc' : 'desc'
    });
  return (
    <div className={className}>
      <div className="table-toolbar">
        {title && <div className="toolbar-title">{title}</div>}
        {(search || buttons) && (
          <Fragment>
            {search && (
              <div className="toolbar-search">
                <div className="field search">
                  <InputField
                    placeholder={searchLabel}
                    input={{
                      value: searchText,
                      onChange: e => {
                        if (isFunction(search)) {
                          return search(e.target.value);
                        }
                        return setSearchText(e.target.value);
                      }
                    }}
                  />
                </div>
                {filters && filters}
              </div>
            )}
            <div className="toolbar-actions">
              {isArray(sortOptions) && (
                <SelectField
                  placeholder={sortLabel}
                  input={{
                    value: sort.by,
                    onChange: setOrder
                  }}
                  options={sortOptions}
                />
              )}
              {buttons && buttons(searchedData)}
            </div>
          </Fragment>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          {!hideTableHeader && (
            <thead className="thead">
              <tr className="tr">
                {map(
                  cols(searchedData),
                  (col, colKey) =>
                    col &&
                    !col.notDisplayCol && (
                      <th
                        key={colKey}
                        className={
                          col.className && col.className + (sort.by === colKey ? ' cln-sorted' : '')
                        }
                        onClick={() => setOrder(colKey)}
                      >
                        <div className="th-content">
                          <div className="entete">
                            {col.label}
                            {col.sublabel ? <div className="sublabel">{col.sublabel}</div> : null}

                            {!col.notSortCol && (
                              <div className="sort-icon">
                                {sort.by === colKey && (
                                  <i
                                    className={`far fa-angle-${
                                      sort.order === 'asc' ? 'down' : 'up'
                                    }`}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </th>
                    )
                )}
                {rowButtons && <th className="twobtn"></th>}
              </tr>
            </thead>
          )}
          <tbody className="tbody">
            {map(
              searchedData,
              (row, rowKey) =>
                row && (
                  <tr className="tr" key={rowKey}>
                    {map(
                      cols(searchedData),
                      (col, colKey) =>
                        col && (
                          <td
                            data-label={col.label}
                            key={colKey}
                            className={col.className}
                            onClick={onClickRow ? () => onClickRow(row) : null}
                          >
                            {(col.render && col.render(row[colKey], row)) ||
                              (col.value && col.value(row[colKey], row)) ||
                              row[colKey]}
                          </td>
                        )
                    )}
                    {rowButtons && <td className="twobtn">{rowButtons(row, rowKey)}</td>}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TableListView.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideTableHeader: PropTypes.bool,
  rowButtons: PropTypes.func,
  onClickRow: PropTypes.func,
  search: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func
  ]),
  searchLabel: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  sortLabel: PropTypes.string,
  sortByDefault: PropTypes.string,
  sortOrderDefault: PropTypes.string
};

export default TableListView;
