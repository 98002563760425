import React from 'react';

import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import BaseLayout from './shared/BaseLayout';

import TranslationsListView from '../components/translations/TranslationsListView';

const TraductionsPage = ({ match: { url }, history: { push }, translations, ...props }) => {
  return (
    <BaseLayout title="TraductionsPage" isBoxContent>
      <Switch>
        {translations && translations[0] && (
          <Route
            exact
            path={url}
            render={() => (
              <TranslationsListView
                initialValues={translations[0].resources}
                translation={translations[0]}
                index={0}
                baseUrl={url}
              />
            )}
          />
        )}
      </Switch>
    </BaseLayout>
  );
};

export default connect(state => ({
  translations: state.data.entities.translations
}))(withRouter(TraductionsPage));
