import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import TableListView from '../shared/TableListView';
import ModalFormProtocole from '../modals/ModalFormProtocole';
import ButtonConfirmModal from '../shared/ButtonConfirmModal';

import { values } from 'lodash';
import { TYPES_PROTOCOLES } from '../../constants/Properties';

const ProtocolesTableListView = ({ protocoles, onClickDelete }) => {
  return (
    <TableListView
      buttons={() => <ModalFormProtocole />}
      className="table-wrapper"
      data={protocoles}
      search={['type', 'nom', 'but']}
      sortByDefault="nom"
      searchLabel={'Rechercher'}
      sortOrderDefault="asc"
      cols={() => ({
        type: {
          label: 'Type',
          value: value => TYPES_PROTOCOLES[value]
        },
        nom: { label: 'Nom' },
        but: { label: 'But' }
      })}
      rowButtons={protocole => (
        <Fragment>
          {protocole && (
            <div className="btn-group">
              <NavLink className="btn" to={`/protocole/${protocole.protocole_id}`}>
                <i className="fas fa-search" />
              </NavLink>

              <ModalFormProtocole protocole={protocole} editMode />
              <ButtonConfirmModal
                message="Voulez-vous vraiment supprimer ce protocole ?"
                actionDelete={() => onClickDelete(protocole.protocole_id)}
              />
            </div>
          )}
        </Fragment>
      )}
    />
  );
};

ProtocolesTableListView.propTypes = {
  protocoles: PropTypes.array.isRequired
};

export default connect(state => ({
  protocoles: state.data.entities.protocoles && values(state.data.entities.protocoles)
}))(ProtocolesTableListView);
