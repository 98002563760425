import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_PROTOCOLES_REQUEST = 'cawa/FETCH_ALL_PROTOCOLES_REQUEST';
const FETCH_ALL_PROTOCOLES_SUCCESS = 'cawa/FETCH_ALL_PROTOCOLES_SUCCESS';
const FETCH_ALL_PROTOCOLES_FAILURE = 'cawa/FETCH_ALL_PROTOCOLES_FAILURE';

const CREATE_PROTOCOLE_REQUEST = 'cawa/CREATE_PROTOCOLE_REQUEST';
const CREATE_PROTOCOLE_SUCCESS = 'cawa/CREATE_PROTOCOLE_SUCCESS';
const CREATE_PROTOCOLE_FAILURE = 'cawa/CREATE_PROTOCOLE_FAILURE';

const DELETE_PROTOCOLE_REQUEST = 'cawa/DELETE_PROTOCOLE_REQUEST';
const DELETE_PROTOCOLE_SUCCESS = 'cawa/DELETE_PROTOCOLE_SUCCESS';
const DELETE_PROTOCOLE_FAILURE = 'cawa/DELETE_PROTOCOLE_FAILURE';

const UPDATE_PROTOCOLE_REQUEST = 'cawa/UPDATE_PROTOCOLE_REQUEST';
const UPDATE_PROTOCOLE_SUCCESS = 'cawa/UPDATE_PROTOCOLE_SUCCESS';
const UPDATE_PROTOCOLE_FAILURE = 'cawa/UPDATE_PROTOCOLE_FAILURE';

export const protocolesActionsHandlers = {
  [FETCH_ALL_PROTOCOLES_REQUEST]: state =>
    flow(set('loaded.protocoles', false), set('loading.protocoles', true))(state),
  [FETCH_ALL_PROTOCOLES_SUCCESS]: (state, action) =>
    flow(
      set('entities.protocoles', action.response.entities.protocoles || {}),
      set('loaded.protocoles', true),
      set('loading.protocoles', false)
    )(state),
  [FETCH_ALL_PROTOCOLES_FAILURE]: state =>
    flow(set('loaded.protocoles', false), set('loading.protocoles', false))(state),

  [CREATE_PROTOCOLE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.protocoles', {
        ...state.entities.protocoles,
        ...action.response.entities.protocoles
      }),
      set('loaded.protocoles', true),
      set('loading.protocoles', false)
    )(state);
  },

  [UPDATE_PROTOCOLE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.protocoles', action.response.entities.protocoles || {}),
      set('loaded.protocoles', true),
      set('loading.protocoles', false)
    )(state);
  },

  [DELETE_PROTOCOLE_SUCCESS]: (state, action) =>
    flow(set('entities.protocoles', omit(state.entities.protocoles, action.protocoleId)))(state)
};

export function createProtocole(data) {
  return {
    [CALL_API]: {
      types: [CREATE_PROTOCOLE_REQUEST, CREATE_PROTOCOLE_SUCCESS, CREATE_PROTOCOLE_FAILURE],
      method: 'POST',
      endpoint: '/protocoles',
      schema: Schemas.PROTOCOLE,
      body: data
    }
  };
}

export function loadProtocoles() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_PROTOCOLES_REQUEST,
        FETCH_ALL_PROTOCOLES_SUCCESS,
        FETCH_ALL_PROTOCOLES_FAILURE
      ],
      method: 'GET',
      endpoint: '/protocoles',
      schema: Schemas.PROTOCOLE_ARRAY,
      successMessage: 'Protocoles chargés avec succès'
    }
  };
}

export function deleteProtocole(protocoleId) {
  return {
    protocoleId,
    [CALL_API]: {
      types: [DELETE_PROTOCOLE_REQUEST, DELETE_PROTOCOLE_SUCCESS, DELETE_PROTOCOLE_FAILURE],
      method: 'DELETE',
      endpoint: `/protocoles/${protocoleId}`,
      successMessage: 'Le protocole a bien été supprimé'
    }
  };
}

export function updateProtocole(protocole, data) {
  return {
    protocole,
    [CALL_API]: {
      types: [UPDATE_PROTOCOLE_REQUEST, UPDATE_PROTOCOLE_SUCCESS, UPDATE_PROTOCOLE_FAILURE],
      method: 'PUT',
      body: data,
      schema: Schemas.PROTOCOLE_ARRAY,
      endpoint: `/protocoles/${protocole.protocole_id}`,
      successMessage: 'Le protocole a bien été modifié'
    }
  };
}
