import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { includes, split, last, take, join, findIndex } from 'lodash';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import getDifference from '../../utils/StringFunctions';
import { updateProtocoleSite } from '../../store/modules/sites';
import { removeFile } from '../../store/modules/uploads';
import MyUploadAdapter from '../../containers/MyUploadAdapter';

const ModalSiteProtocoleHtmlEdit = ({
  protocoleId,
  site,
  user,
  updateProtocoleSite,
  removeFile
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [nameFile, setNameFile] = useState('');
  const [dataCkeditor, setDataCkeditor] = useState('');

  const indexProtocole = findIndex(site.protocoles, s => s.protocole_id === protocoleId);

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, user, site.site_id);
    };
  }
  const handleSubmit = data => {
    const protocoles = site.protocoles;
    protocoles[indexProtocole].description = data.description;

    return updateProtocoleSite(site, protocoles)
      .then(() => setIsOpen(false))
      .catch(err => setError(err));
  };
  return (
    <Fragment>
      <button className="btn" onClick={() => setIsOpen(!isOpen)}>
        <i className="fas fa-pencil" />
      </button>
      <Modal
        className="modal-content medium-modal"
        overlayClassName="modal-overlay"
        id="modale-sites"
        isOpen={isOpen}
      >
        <div className="modal-title">
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  initialValue={
                    (site.protocoles[indexProtocole] &&
                      site.protocoles[indexProtocole].description) ||
                    ''
                  }
                  name="description"
                  parse={value => value}
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          extraPlugins: [MyCustomUploadAdapterPlugin],
                          toolbar: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            '|',
                            'fontSize',
                            'fontColor',
                            'fontBackgroundColor',
                            '|',
                            'alignment',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            '|',
                            'link',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'blockQuote',
                            '|',
                            'imageUpload',
                            '|',
                            'insertTable',
                            'tableColumn',
                            'tableRow',
                            '|',
                            'viewSource'
                          ]
                        }}
                        data={input.value}
                        onChange={(value, editor) => {
                          let data = editor.getData();
                          // si dataCkeditor contient la balise <image>,
                          // et que la variable data ne la contient plus, alors c'est que l'image a été supprimer
                          const isImageDelete =
                            includes(dataCkeditor, '<img src=') && !includes(data, '<img src=');

                          const difference = getDifference(dataCkeditor, data);
                          if (includes(difference, '/api/uploads/')) {
                            setNameFile(
                              join(
                                take(
                                  last(split(difference, '/')),
                                  last(split(difference, '/')).length - 1
                                ),
                                ''
                              )
                            );
                          }
                          if (isImageDelete) {
                            // il faut supprimer l'image sur le server.
                            removeFile(nameFile);
                          }
                          setDataCkeditor(data);
                          input.onChange(data);
                        }}
                      />
                      <label>Description du protocole pour ce site</label>
                    </div>
                  )}
                </Field>

                {error && <div className="error-message">{error}</div>}
                <div className="modal-footer">
                  <div className="btn-group">
                    <button onClick={() => setIsOpen(false)} className="btn btn-secondary">
                      <span>Annuler</span>
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalSiteProtocoleHtmlEdit.propTypes = {
  updateProtocoleSite: PropTypes.func.isRequired
};

export default connect(state => ({ user: state.auth.user }), {
  updateProtocoleSite,
  removeFile
})(ModalSiteProtocoleHtmlEdit);
