export default {
  entities: {
    utilisateurs: {},
    protocoles: {},
    observations: {},
    sites: {}
  },
  loaded: {
    appstorage: false,
    utilisateurs: false,
    protocoles: false,
    observations: false,
    sites: false
  },
  loading: {
    utilisateurs: false,
    protocoles: false,
    observations: false,
    sites: false
  }
};
