import { schema } from 'normalizr';

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr
const userSchema = new schema.Entity('user', {}, { idAttribute: 'user_id' });

const observationSchema = new schema.Entity('observations', {}, { idAttribute: 'observation_id' });
const siteSchema = new schema.Entity('sites', {}, { idAttribute: 'site_id' });
const translationSchema = new schema.Entity('translations', {}, { idAttribute: 'translation_id' });
const protocoleSchema = new schema.Entity('protocoles', {}, { idAttribute: 'protocole_id' });
const utilisateurSchema = new schema.Entity('utilisateurs', {}, { idAttribute: 'user_id' });
// Schemas for API responses.
export const Schemas = {
  USER: userSchema,
  UTILISATEUR: utilisateurSchema,
  UTILISATEUR_ARRAY: new schema.Array(utilisateurSchema),
  OBSERVATION: observationSchema,
  OBSERVATION_ARRAY: new schema.Array(observationSchema),
  TRANSLATION: translationSchema,
  TRANSLATION_ARRAY: new schema.Array(translationSchema),
  SITE: siteSchema,
  SITE_ARRAY: new schema.Array(siteSchema),
  PROTOCOLE: protocoleSchema,
  PROTOCOLE_ARRAY: new schema.Array(protocoleSchema)
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
