import React from 'react';

import { NavLink } from 'react-router-dom';

const SideBar = () => {
  return (
    <nav className="sidebar">
      <img className="logo" src={require(`../../../assets/images/logo-coastappli-blanc.svg`)} />
      <ul className="sidebar-menu">
        <li>
          <NavLink exact to={'/utilisateurs'}>
            <i className="fal fa-users" />
            <span>Utilisateurs</span>
          </NavLink>
        </li>
        <li>
          <NavLink exact to={'/protocoles'}>
            <i className="fal fa-clipboard-list" />
            <span>Protocoles</span>
          </NavLink>
        </li>
        <li>
          <NavLink exact to={'/sites'}>
            <i className="fas fa-map-marked-alt" />
            <span>Sites d’observation</span>
          </NavLink>
        </li>
        <li>
          <NavLink exact to={'/traductions'}>
            <i className="fal fa-language" />
            <span>Traductions</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/administrateurs">
            <i className="fal fa-users-cog" />
            <span>Administrateurs</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SideBar;
