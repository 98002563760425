import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateUtilisateur } from '../../store/modules/utilisateurs';

import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { find, map } from 'lodash';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import { TYPES_PROFILS } from '../../constants/Properties';

const ModalFormUtilisateur = ({ utilisateur, updateUtilisateur }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    return updateUtilisateur(utilisateur, values)
      .then(() => setIsOpen(false))
      .catch(err => setError(err));
  };

  const optionsProfil = map(TYPES_PROFILS, (item, key) => {
    return { value: key, label: item };
  });

  return (
    <Fragment>
      <button className="btn" onClick={() => setIsOpen(!isOpen)}>
        <i className="fas fa-pencil" />
      </button>
      <Modal {...modalDefaultClass} id="modal-utilisateurs" isOpen={isOpen}>
        <div className="modal-title">
          Modifier un utilisateur
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  validate={required}
                  initialValue={(utilisateur && utilisateur.profil) || null}
                  name="profil"
                  component="select"
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <Select
                          options={optionsProfil}
                          placeholder="Sélectionner un profil"
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsProfil, o => o.value === input.value)}
                        />
                        <label>Profil</label>
                      </div>
                    );
                  }}
                </Field>

                {error && <div className="error-message">{error}</div>}
                <div className="modal-footer">
                  <div className="btn-group">
                    <button onClick={() => setIsOpen(false)} className="btn btn-secondary">
                      <span>Annuler</span>
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormUtilisateur.propTypes = {
  updateUtilisateur: PropTypes.func.isRequired
};

export default connect(null, {
  updateUtilisateur
})(ModalFormUtilisateur);
