import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { includes, split, last, take, join } from 'lodash';

import { createSite, updateSite } from '../../store/modules/sites';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import getDifference from '../../utils/StringFunctions';
import { removeFile } from '../../store/modules/uploads';

import { Form, Field } from 'react-final-form';

import Modal from 'react-modal';
import MyUploadAdapter from '../../containers/MyUploadAdapter';

const ModalFormSite = ({ site, editMode, updateSiteProps, createSiteProps, removeFile, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [nameFile, setNameFile] = useState('');
  const [dataCkeditor, setDataCkeditor] = useState('');

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader, user, site.site_id);
    };
  }

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    console.log('handleSubmit values', values);
    let data = values;
    if (editMode) {
      return updateSiteProps(site, data)
        .then(() => setIsOpen(false))
        .catch(err => setError(err));
    } else {
      return createSiteProps(data)
        .then(() => {
          setIsOpen(false);
        })
        .catch(err => setError(err));
    }
  };

  return (
    <Fragment>
      {!editMode ? (
        <button
          onClick={() => {
            setError(null);
            setIsOpen(!isOpen);
          }}
          className="btn btn-primary"
        >
          <i className="fas fa-plus-circle" />
          <span>Ajouter un Site</span>
        </button>
      ) : (
        <button className="btn" onClick={() => setIsOpen(!isOpen)}>
          <i className="fas fa-pencil" />
        </button>
      )}

      <Modal
        className="modal-content medium-modal"
        overlayClassName="modal-overlay"
        id="modale-sites"
        isOpen={isOpen}
      >
        <div className="modal-title">
          {!editMode ? 'Ajouter un site' : 'Modifier un site'}
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  initialValue={(editMode && site && site.nom) || ''}
                  validate={required}
                  name="nom"
                  parse={value => value}
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Nom *</label>
                    </div>
                  )}
                </Field>
                <div>
                  <hr />
                  <p className="field">Localisation</p>
                  <Field
                    initialValue={(editMode && site && site.latitude) || ''}
                    validate={required}
                    name="latitude"
                    parse={value => value}
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" pattern="^(-|)(0|[1-9]\d*)([.,]\d*)?$" />
                        <label>Latitude *</label>
                      </div>
                    )}
                  </Field>
                  <Field
                    initialValue={(editMode && site && site.longitude) || ''}
                    validate={required}
                    name="longitude"
                    parse={value => value}
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" pattern="^(-|)(0|[1-9]\d*)([.,]\d*)?$" />
                        <label>Longitude *</label>
                      </div>
                    )}
                  </Field>
                  <hr />
                </div>
                <Field
                  initialValue={(editMode && site && site.referent) || ''}
                  name="referent"
                  parse={value => value}
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Référent(s) sur site</label>
                    </div>
                  )}
                </Field>
                <Field
                  initialValue={(editMode && site && site.description) || ''}
                  name="description"
                  component="input"
                  className="field"
                  parse={value => value}
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          extraPlugins: [MyCustomUploadAdapterPlugin],
                          toolbar: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            '|',
                            'fontSize',
                            'fontColor',
                            'fontBackgroundColor',
                            '|',
                            'alignment',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            '|',
                            'link',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'blockQuote',
                            '|',
                            'imageUpload',
                            '|',
                            'insertTable',
                            'tableColumn',
                            'tableRow',
                            '|',
                            'viewSource'
                          ]
                        }}
                        data={input.value}
                        onChange={(value, editor) => {
                          let data = editor.getData();
                          // si dataCkeditor contient la balise <image>,
                          // et que la variable data ne la contient plus, alors c'est que l'image a été supprimer
                          const isImageDelete =
                            includes(dataCkeditor, '<img src=') && !includes(data, '<img src=');

                          const difference = getDifference(dataCkeditor, data);
                          if (includes(difference, '/api/uploads/')) {
                            setNameFile(
                              join(
                                take(
                                  last(split(difference, '/')),
                                  last(split(difference, '/')).length - 1
                                ),
                                ''
                              )
                            );
                          }
                          if (isImageDelete) {
                            // il faut supprimer l'image sur le server.
                            removeFile(nameFile);
                          }
                          input.onChange(data);
                        }}
                      />
                      <label>Description</label>
                    </div>
                  )}
                </Field>

                {error && <div className="error-message">{error}</div>}
                <div className="modal-footer">
                  <div className="btn-group">
                    <button onClick={() => setIsOpen(false)} className="btn btn-secondary">
                      <span>Annuler</span>
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormSite.propTypes = {
  createSiteProps: PropTypes.func.isRequired,
  updateSiteProps: PropTypes.func.isRequired
};

export default connect(state => ({ user: state.auth.user }), {
  createSiteProps: createSite,
  updateSiteProps: updateSite,
  removeFile
})(ModalFormSite);
