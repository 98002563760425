import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { updateUtilisateur } from '../../store/modules/utilisateurs';
import { validateEmail } from '../../utils/StringValidator';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalFormEditAdmin = ({ admin, updateUtilisateur }) => {
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    return updateUtilisateur(admin, values)
      .then(() => setIsOpen(false))
      .catch(err => setError(err));
  };

  return (
    <Fragment>
      <button
        className="btn"
        onClick={() => {
          setIsOpen(!isOpen);
          setError(null);
        }}
      >
        <i className="fas fa-pencil" />
      </button>

      <Modal {...modalDefaultClass} id="edit-admin" isOpen={isOpen}>
        <div className="modal-title">
          Modifier un administrateur
          <div
            onClick={() => {
              setError(null);
              setIsOpen(false);
            }}
            className="btn-close"
          >
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  validate={required}
                  initialValue={(admin && admin.prenom) || ''}
                  name="prenom"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Prénom *</label>
                    </div>
                  )}
                </Field>
                <Field
                  validate={required}
                  initialValue={(admin && admin.nom) || ''}
                  name="nom"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Nom *</label>
                    </div>
                  )}
                </Field>
                <Field
                  validate={requiredEmail}
                  initialValue={(admin && admin.email) || ''}
                  name="email"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" />
                      <label>Adresse e-mail *</label>
                    </div>
                  )}
                </Field>

                {error && <div className="error-message">{error}</div>}
                <div className="modal-footer">
                  <div className="btn-group">
                    <button onClick={() => setIsOpen(false)} className="btn btn-secondary">
                      <span>Annuler</span>
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormEditAdmin.propTypes = {
  admin: PropTypes.object.isRequired,
  updateUtilisateur: PropTypes.func.isRequired
};

export default connect(null, { updateUtilisateur })(ModalFormEditAdmin);
