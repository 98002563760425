import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import TableListView from '../shared/TableListView';
import ModalFormUtilisateur from '../modals/ModalFormUtilisateur';

import { values } from 'lodash';
import { TYPES_PROFILS, BADGES_EXPLORATEUR, BADGES_FREQUENCE } from '../../constants/Properties';

const UtilisateursTableListView = ({ utilisateurs }) => {
  return (
    <TableListView
      className="table-wrapper"
      data={utilisateurs}
      search={['nom', 'email']}
      sortByDefault="nom"
      searchLabel={'Rechercher'}
      sortOrderDefault="asc"
      cols={() => ({
        nom: {
          label: 'Nom'
        },
        email: { label: 'Email' },
        badges: {
          label: 'Badges',
          render: value => (
            <div>
              {(value && value.explorateur && value.explorateur !== 0 && (
                <img
                  title={`${BADGES_EXPLORATEUR[value.explorateur].labelType} ${
                    BADGES_EXPLORATEUR[value.explorateur].labelBadge
                  }`}
                  style={{
                    width: 26,
                    marginRight: 5
                  }}
                  src={BADGES_EXPLORATEUR[value.explorateur].img}
                />
              )) ||
                null}
              {(value && value.frequence && value.frequence !== 0 && (
                <img
                  title={`${BADGES_FREQUENCE[value.frequence].labelType} ${
                    BADGES_FREQUENCE[value.frequence].labelBadge
                  }`}
                  style={{
                    width: 26,
                    marginRight: 5
                  }}
                  src={BADGES_FREQUENCE[value.frequence].img}
                />
              )) ||
                null}
            </div>
          )
        },
        profil: {
          label: 'Profil',
          value: value => TYPES_PROFILS[value]
        }
      })}
      rowButtons={utilisateur => (
        <Fragment>
          {utilisateur && (
            <div className="btn-group">
              <ModalFormUtilisateur utilisateur={utilisateur} />
            </div>
          )}
        </Fragment>
      )}
    />
  );
};

UtilisateursTableListView.propTypes = {
  utilisateurs: PropTypes.array.isRequired
};

export default connect(state => ({
  utilisateurs: state.data.entities.utilisateurs && values(state.data.entities.utilisateurs)
}))(UtilisateursTableListView);
