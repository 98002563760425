import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_UTILISATEURS_REQUEST = 'cawa/FETCH_ALL_UTILISATEURS_REQUEST';
const FETCH_ALL_UTILISATEURS_SUCCESS = 'cawa/FETCH_ALL_UTILISATEURS_SUCCESS';
const FETCH_ALL_UTILISATEURS_FAILURE = 'cawa/FETCH_ALL_UTILISATEURS_FAILURE';

const CREATE_ADMIN_REQUEST = 'cawa/CREATE_ADMIN_REQUEST';
const CREATE_ADMIN_SUCCESS = 'cawa/CREATE_ADMIN_SUCCESS';
const CREATE_ADMIN_FAILURE = 'cawa/CREATE_ADMIN_FAILURE';

const CREATE_UTILISATEUR_REQUEST = 'cawa/CREATE_UTILISATEUR_REQUEST';
const CREATE_UTILISATEUR_SUCCESS = 'cawa/CREATE_UTILISATEUR_SUCCESS';
const CREATE_UTILISATEUR_FAILURE = 'cawa/CREATE_UTILISATEUR_FAILURE';

const DELETE_UTILISATEUR_REQUEST = 'cawa/DELETE_UTILISATEUR_REQUEST';
const DELETE_UTILISATEUR_SUCCESS = 'cawa/DELETE_UTILISATEUR_SUCCESS';
const DELETE_UTILISATEUR_FAILURE = 'cawa/DELETE_UTILISATEUR_FAILURE';

const UPDATE_UTILISATEUR_REQUEST = 'cawa/UPDATE_UTILISATEUR_REQUEST';
const UPDATE_UTILISATEUR_SUCCESS = 'cawa/UPDATE_UTILISATEUR_SUCCESS';
const UPDATE_UTILISATEUR_FAILURE = 'cawa/UPDATE_UTILISATEUR_FAILURE';

export const utilisateursActionsHandlers = {
  [FETCH_ALL_UTILISATEURS_REQUEST]: state =>
    flow(set('loaded.utilisateurs', false), set('loading.utilisateurs', true))(state),
  [FETCH_ALL_UTILISATEURS_SUCCESS]: (state, action) => {
    return flow(
      set('entities.utilisateurs', action.response.entities.utilisateurs || {}),
      set('loaded.utilisateurs', true),
      set('loading.utilisateurs', false)
    )(state);
  },
  [FETCH_ALL_UTILISATEURS_FAILURE]: state =>
    flow(set('loaded.utilisateurs', false), set('loading.utilisateurs', false))(state),

  [CREATE_ADMIN_SUCCESS]: (state, action) => {
    return flow(
      set('entities.utilisateurs', {
        ...state.entities.utilisateurs,
        ...action.response.entities.utilisateurs
      }),
      set('loaded.utilisateurs', true),
      set('loading.utilisateurs', false)
    )(state);
  },

  [CREATE_UTILISATEUR_SUCCESS]: (state, action) => {
    return flow(
      set('entities.utilisateurs', {
        ...state.entities.utilisateurs,
        ...action.response.entities.utilisateurs
      }),
      set('loaded.utilisateurs', true),
      set('loading.utilisateurs', false)
    )(state);
  },

  [UPDATE_UTILISATEUR_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.utilisateurs.${action.user.user_id}`, {...action.user, ...action.response}),
      set('loaded.utilisateurs', true),
      set('loading.utilisateurs', false)
    )(state);
  },

  [DELETE_UTILISATEUR_SUCCESS]: (state, action) =>
    flow(set('entities.utilisateurs', omit(state.entities.utilisateurs, action.userId)))(state)
};

export function loadAdministrateurs() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_UTILISATEURS_REQUEST,
        FETCH_ALL_UTILISATEURS_SUCCESS,
        FETCH_ALL_UTILISATEURS_FAILURE
      ],
      method: 'GET',
      endpoint: '/users/administrateurs',
      schema: Schemas.UTILISATEUR_ARRAY,
      successMessage: 'Administrateurs chargés avec succès'
    }
  };
}

export function createAdmin(data) {
  return {
    [CALL_API]: {
      types: [CREATE_ADMIN_REQUEST, CREATE_ADMIN_SUCCESS, CREATE_ADMIN_FAILURE],
      method: 'POST',
      endpoint: '/users/administrateurs',
      schema: Schemas.UTILISATEUR,
      body: data
    }
  };
}

export function createUtilisateur(data) {
  return {
    [CALL_API]: {
      types: [CREATE_UTILISATEUR_REQUEST, CREATE_UTILISATEUR_SUCCESS, CREATE_UTILISATEUR_FAILURE],
      method: 'POST',
      endpoint: '/users/utilisateurs',
      schema: Schemas.UTILISATEUR,
      body: data
    }
  };
}

export function loadUtilisateurs() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_UTILISATEURS_REQUEST,
        FETCH_ALL_UTILISATEURS_SUCCESS,
        FETCH_ALL_UTILISATEURS_FAILURE
      ],
      method: 'GET',
      endpoint: '/users/utilisateurs',
      schema: Schemas.UTILISATEUR_ARRAY,
      successMessage: 'Utilisateurs chargés avec succès'
    }
  };
}

export function deleteUtilisateur(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [DELETE_UTILISATEUR_REQUEST, DELETE_UTILISATEUR_SUCCESS, DELETE_UTILISATEUR_FAILURE],
      method: 'DELETE',
      endpoint: `/users/${userId}`,
      successMessage: 'Le compte a bien été supprimé'
    }
  };
}

export function updateUtilisateur(user, data) {
  return {
    user,
    [CALL_API]: {
      types: [UPDATE_UTILISATEUR_REQUEST, UPDATE_UTILISATEUR_SUCCESS, UPDATE_UTILISATEUR_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/users/${user.user_id}`,
      successMessage: 'Le compte a bien été modifié'
    }
  };
}
