import React from 'react';
import PropTypes from 'prop-types';

const HeaderTableau = ({ name }) => {
  return (
    <div className="header-client">
      <div className="container">
        <div className="card-infos">
          <div className="card-title">{name}</div>
        </div>
      </div>
    </div>
  );
};

HeaderTableau.propTypes = {
  name: PropTypes.string.isRequired
};
export default HeaderTableau;
