import fetch from 'isomorphic-fetch';
import FormData from 'form-data';

import { API_URL } from '../constants/Config';

export default class MyUploadAdapter {
  constructor(loader, user, siteId) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.user = user;
    this.siteId = siteId;
    this.options = {};
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          //   this._initListeners(resolve, reject, file);
          this._sendRequest(file, resolve, reject);
        })
    );
  }

  // Prepares the data and sends the request.
  _sendRequest(file, resolve, reject) {
    // Prepare the form data.
    const data = new FormData();

    data.append('attachment', file);
    data.append('siteId', this.siteId);
    fetch(`${API_URL}/uploads/ckeditor`, { body: data, method: 'POST' })
      .then(response => response.json().then(json => ({ json, response })))
      .then(({ json, response }) => {
        if (response.status !== 200) {
          return reject(json.message);
        }
        const responseData = json.data || json;
        return resolve({
          default: responseData.filename
        });
      });
  }
}
