import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';

import { addProtocoleSite } from '../../store/modules/sites';

import { Form, Field } from 'react-final-form';
import { filter, map, includes } from 'lodash';

import { TYPES_PROTOCOLES } from '../../constants/Properties';

const ModalFormAddProtocole = ({ site, sites, protocolesProps, editMode, addProtocoleSite }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const optionsTypesProtocoles = map(TYPES_PROTOCOLES, (item, key) => {
    return { value: key, label: item };
  });

  const protocolesIdsExist = map(site.protocoles, p => p.protocole_id);

  const [protocoles, setProtocoles] = useState(
    map(protocolesProps, protocole => {
      return {
        value: protocole.protocole_id,
        label: protocole.nom,
        type: protocole.type
      };
    })
  );

  const [erosionProtocoles, setErosionProtocoles] = useState(
    filter(
      protocoles,
      protocole => protocole.type === 'EROSION' && !includes(protocolesIdsExist, protocole.value)
    )
  );
  const [submersionProtocoles, setSubmersionProtocoles] = useState(
    filter(
      protocoles,
      protocole => protocole.type === 'EROSION' && !includes(protocolesIdsExist, protocole.value)
    )
  );

  useEffect(() => {
    setProtocoles(
      map(protocolesProps, protocole => {
        return { value: protocole.protocole_id, label: protocole.nom, type: protocole.type };
      })
    );
  }, [sites]);

  useEffect(() => {
    setErosionProtocoles(
      filter(protocoles, function(protocole) {
        return protocole.type == 'EROSION' && !includes(protocolesIdsExist, protocole.value);
      })
    );
  }, [sites]);

  useEffect(() => {
    setSubmersionProtocoles(
      filter(protocoles, function(protocole) {
        return protocole.type == 'SUBMERSION' && !includes(protocolesIdsExist, protocole.value);
      })
    );
  }, [sites]);

  const groupeOptions = [
    { label: optionsTypesProtocoles[0].value, options: erosionProtocoles },
    {
      label: optionsTypesProtocoles[1].value,
      options: submersionProtocoles
    }
  ];

  const handleSubmit = values => {
    const protocoles = map(values.protocoles, protocoleId => ({
      protocole_id: protocoleId
    }));

    return addProtocoleSite(site, protocoles)
      .then(() => setIsOpen(false))
      .catch(err => setError(err));
  };

  const customStyles = { multiValueRemove: base => ({ ...base, display: 'none' }) };

  return (
    <Fragment>
      {!editMode ? (
        <button
          onClick={() => {
            setError(null);
            setIsOpen(!isOpen);
          }}
          className="btn btn-primary"
        >
          <i className="fas fa-plus-circle" />
          <span>Ajouter un/ou des Protocoles au Site</span>
        </button>
      ) : (
        <button className="btn" onClick={() => setIsOpen(!isOpen)}>
          <i className="fas fa-pencil" />
        </button>
      )}

      <Modal
        className="modal-content medium-modal"
        overlayClassName="modal-overlay"
        id="modale-sites"
        isOpen={isOpen}
      >
        <div className="modal-title">
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="field">
                  <Field
                    initialValue={(site && site.protocoles) || ''}
                    name="protocoles"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <Select
                          styles={customStyles}
                          onChange={protocoles => {
                            input.onChange(map(protocoles, v => v.value));
                          }}
                          placeholder="Sélectionner un/ou des protocoles"
                          isMulti
                          autoFocus
                          isClearable={false}
                          backspaceRemovesValue={false}
                          options={groupeOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <label>Protocoles</label>
                      </div>
                    )}
                  </Field>
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="modal-footer">
                  <div className="btn-group">
                    <button onClick={() => setIsOpen(false)} className="btn btn-secondary">
                      <span>Annuler</span>
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormAddProtocole.propTypes = {
  addProtocoleSite: PropTypes.func.isRequired
};

export default connect(
  state => ({
    protocolesProps: state.data.entities.protocoles,
    sites: state.data.entities.sites
  }),
  {
    addProtocoleSite
  }
)(ModalFormAddProtocole);
