import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import TableListView from '../shared/TableListView';
import ModalFormSite from '../modals/ModalFormSite';
import ButtonConfirmModal from '../shared/ButtonConfirmModal';

import { values } from 'lodash';

const SitesTableListView = ({ sites, protocoles, onClickDelete }) => {
  return (
    <TableListView
      buttons={() => <ModalFormSite protocoles={protocoles} />}
      className="table-wrapper"
      data={sites}
      search={['nom']}
      sortByDefault="nom"
      searchLabel={'Rechercher'}
      sortOrderDefault="asc"
      cols={() => ({
        nom: { label: 'Nom' }
      })}
      rowButtons={site => (
        <div>
          {site ? (
            <div className="btn-group">
              <NavLink className="btn" to={`/site/${site.site_id}`}>
                <i className="fas fa-search" />
              </NavLink>
              <ModalFormSite site={site} protocoles={protocoles} editMode />
              <ButtonConfirmModal
                message="Voulez-vous vraiment supprimer ce site ?"
                actionDelete={() => onClickDelete(site.site_id)}
              />
            </div>
          ) : null}
        </div>
      )}
    />
  );
};

SitesTableListView.propTypes = {
  sites: PropTypes.array.isRequired,
  protocoles: PropTypes.array.isRequired
};

export default connect(state => ({
  sites: state.data.entities.sites && values(state.data.entities.sites),
  protocoles: state.data.entities.protocoles && values(state.data.entities.protocoles)
}))(SitesTableListView);
