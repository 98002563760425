import React, { useEffect } from 'react';
import { withRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';

import { requireAuthentication } from '../components/hoc/AuthenticatedComponent';

import LoginPage from './auth/LoginPage';
import CreatePasswordPage from './CreatePasswordPage';
import PasswordOubliePage from './auth/PasswordOubliePage';

import AdministrateursPage from './AdministrateursPage';

import PageNotFound from './PageNotFound';
import ProtocolesPage from './ProtocolesPage';
import UtilisateursPage from './UtilisateursPage';
import TraductionsPage from './TraductionsPage';
import SitesPage from './SitesPage';
import ProtocolePage from './ProtocolePage';
import SitePage from './SitePage';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Router = function() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          {/* Route avec authentification */}
          <Route
            exact
            path="/administrateurs"
            component={requireAuthentication(AdministrateursPage)}
          />
          <Route exact path="/protocoles" component={requireAuthentication(ProtocolesPage)} />
          <Route
            exact
            path="/protocole/:protocoleId"
            component={requireAuthentication(ProtocolePage)}
          />
          <Route exact path="/utilisateurs" component={requireAuthentication(UtilisateursPage)} />
          <Route exact path="/traductions" component={TraductionsPage} />
          <Route exact path="/sites" component={requireAuthentication(SitesPage)} />
          <Route exact path="/site/:siteId" component={requireAuthentication(SitePage)} />
          {/* Fin des Routes avec authentification */}
          <Route
            exact
            path="/definir-mot-de-passe/:role?/:email?/:userid?/:token?"
            component={CreatePasswordPage}
          />

          <Route exact path="/mot-de-passe-oublie" component={PasswordOubliePage} />

          {/* Par default, on envoie la page sur login */}
          <Route exact path="*" component={LoginPage} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
