import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { FormSection, reduxForm, formValueSelector, Field } from 'redux-form';
import { forEach, includes, isNull, split } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { editTranslation } from '../../store/modules/translations';

import { InputField, SelectField } from 'react-components-linaia';

import { map } from 'lodash';

const TranslationsListView = ({
  editTranslation,
  handleSubmit,
  translation,
  baseUrl,
  index,
  t
}) => {
  const [allLangage, setAllLangage] = useState(
    map(translation.resources, (langage, index) => index)
  );
  const [langageSelected, setLangageSelected] = useState({ index: 0, value: allLangage[0] });
  const submit = v => {
    editTranslation(v, index);
  };

  const fieldTranslation = (param, index, nameField) => {
    // if is an object we call again
    if (typeof index[param] === 'object') {
      return map(index[param], (trans, paramObj, indexObj) => {
        return fieldTranslation(paramObj, indexObj, param + '.' + paramObj);
      });
    }

    let nameFr = translation.resources.fr.translations[nameField];

    // method to transform string "example.to.get" to "exemple : { to : { get: stringToRecup}}}"
    let nameSplit = split(nameField, '.');
    if (nameSplit.length > 1) {
      let nameToGet = null;
      forEach(split(nameField, '.'), objToGet => {
        if (isNull(nameToGet)) {
          nameToGet = translation.resources.fr.translations[objToGet];
        } else {
          nameToGet = nameToGet[objToGet];
        }
        nameFr = nameToGet;
      });
    }
    // end of the method

    return (
      // 3rd columns
      <tr key={nameField}>
        {/* Code du texte */}
        <td>{nameField}</td>
        {/* texte en francais (si langue choisie est francais, on ne l'affiche pas) */}
        {langageSelected.value !== 'fr' && <td>{nameFr}</td>}
        {/* traduction dans la langue choisie */}
        <td>
          <Field name={nameField} component={InputField} label={nameField} />
        </td>
      </tr>
    );
  };
  const mapFieldTranslation = langage => {
    return map(langage.translations, (trans, param, index) => {
      // define recursive function becasue param must be a Object and not a string
      return fieldTranslation(param, index, param);
    });
  };
  return (
    <Fragment>
      <div class="container">
        <form onSubmit={handleSubmit(submit)}>
          <div class="page-header">
            <div class="page-header-left">
              <div class="page-header-title">Traductions</div>
            </div>
          </div>
          <div className="table-wrapper">
            <div class="table-toolbar">
              <div class="toolbar-search">
                <div class="">
                  <SelectField
                    placeholder={'Selectionner le langage'}
                    isSearchable={false}
                    isClearable={false}
                    classNamePrefix="select"
                    input={{
                      label: langageSelected.label,
                      value: langageSelected.index,
                      onChange: v => setLangageSelected({ index: v, value: allLangage[v] })
                    }}
                    options={map(allLangage, (v, index) => ({ label: v, value: index }))}
                  />
                </div>
              </div>
              <div class="toolbar-actions">
                <button className="btn btn-primary" type="submit">
                  Enregistrer
                </button>
              </div>
            </div>
            {translation &&
              map(translation.resources, (langage, index) => {
                return (
                  <FormSection key={index} name={index + '.translations'}>
                    {index === langageSelected.value ? (
                      <div className="table-container">
                        <table className="table table-fixed">
                          <thead className="thead">
                            <tr className="tr">
                              <th className="code">Code du texte</th>
                              {langageSelected.value !== 'fr' && (
                                <th className="text-fr">Texte en français</th>
                              )}
                              <th>Traduction</th>
                            </tr>
                          </thead>
                          <tbody className="tbody">{mapFieldTranslation(langage)}</tbody>
                        </table>
                      </div>
                    ) : null}
                  </FormSection>
                );
              })}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

TranslationsListView.propTypes = {
  baseUrl: PropTypes.string.isRequired
};
const selector = formValueSelector('Translation');
const TranslationsListViewForm = connect(() => ({}), { editTranslation })(
  reduxForm({
    form: 'Translation',
    validate: (values = {}) => {
      const errors = {};

      return errors;
    }
  })(TranslationsListView)
);
export default withTranslation()(TranslationsListViewForm);
