import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { split, includes, lowerCase } from 'lodash';

export function requireAuthentication(Component) {
  const AuthenticatedComponent = props => {
    useEffect(() => {
      checkAuth();
    }, []);

    useEffect(() => {
      checkAuth();
    }, [props.location]);

    const checkAuth = () => {
      // check le role si il a accès a la page. sinon redirection (on saute cet étape si on veut acceder a la page tableau de bord)

      if (!props.user) {
        const redirectAfterLogin = props.location.pathname;
        props.history.push(`/login?next=${redirectAfterLogin}`);
      }
    };
    return <Fragment>{props.user ? <Component {...props} /> : null}</Fragment>;
  };

  const mapStateToProps = state => ({
    user: state.auth.user
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
