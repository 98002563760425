import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { NavLink, useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { validateEmail } from '../../utils/StringValidator';
import { login } from '../../store/modules/auth';
import { version } from '../../../package.json';

const LoginPage = ({ login }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const required = value => (value ? undefined : 'Champ requis !');

  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const handleSubmit = values => {
    return login(values.email, values.password)
      .then(res => {
        return history.push('utilisateurs');
      })
      .catch(err => setError(err));
  };

  return (
    <Fragment>
      <div className="login">
        <div className="login-overflow">
          <div className="box animated">
            <div className="box-header">
              <div className="app-logo-block">
                <img className="logo" src={require(`../../../assets/images/logo-coastappli.jpg`)} />
                <div className="app-version">v.{version}</div>
              </div>
              <h1>Connexion</h1>
            </div>
            <div className="box-content">
              <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="email"
                      validate={requiredEmail}
                      component="input"
                      className="field"
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Email</label>
                        </div>
                      )}
                    </Field>

                    <Field validate={required} name="password" component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input
                            {...input}
                            type="password"
                            onChange={value => {
                              setError(null);
                              input.onChange(value);
                            }}
                          />
                          <label>Mot de passe</label>
                        </div>
                      )}
                    </Field>

                    {error && <div className="error-message">{error}</div>}
                    <div className="field checkbox">
                      <input type="checkbox" name="connect" checked />
                      <label>Rester connecté(e)</label>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      <span>Je me connecte</span>
                    </button>
                    <NavLink className="btn-link" to="/mot-de-passe-oublie">
                      Mot de passe oublié
                    </NavLink>
                  </form>
                )}
              />
            </div>
          </div>
          <div className="login-aside">
            <img className="logo" src={require(`../../../assets/images/logo-coastappli.jpg`)} />
            <div className="app-version">v.{version}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { login })(LoginPage);
