import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createProtocole, updateProtocole } from '../../store/modules/protocoles';

import Select from 'react-select';

import { Form, Field } from 'react-final-form';
import { find, map } from 'lodash';

import Modal from 'react-modal';

import { TYPES_PROFILS, TYPES_PHOTOS, TYPES_PROTOCOLES } from '../../constants/Properties';

const ModalFormProtocole = ({ protocole, editMode, updateProtocole, createProtocole }) => {
  const optionsTypes = map(TYPES_PROTOCOLES, (item, key) => {
    return { value: key, label: item };
  });

  const optionsProfil = map(TYPES_PROFILS, (item, key) => {
    return { value: key, label: item };
  });

  const optionsPhoto = map(TYPES_PHOTOS, (item, key) => {
    return { value: key, label: item };
  });

  const optionsBoolean = [
    {
      value: true,
      label: 'Oui'
    },
    {
      value: false,
      label: 'Non'
    }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    let data = values;

    if (editMode) {
      return updateProtocole(protocole, data)
        .then(() => setIsOpen(false))
        .catch(err => setError(err));
    } else {
      return createProtocole(data)
        .then(() => {
          setIsOpen(false);
          setMesures([]);
        })
        .catch(err => setError(err));
    }
  };

  return (
    <Fragment>
      {!editMode ? (
        <button
          onClick={() => {
            setError(null);
            setIsOpen(!isOpen);
          }}
          className="btn btn-primary"
        >
          <i className="fas fa-plus-circle" />
          <span>Ajouter un Protocole</span>
        </button>
      ) : (
        <button className="btn" onClick={() => setIsOpen(!isOpen)}>
          <i className="fas fa-pencil" />
        </button>
      )}

      <Modal
        className="modal-content medium-modal"
        overlayClassName="modal-overlay"
        id="modal-protocole"
        isOpen={isOpen}
      >
        <div className="modal-title">
          {!editMode ? 'Ajouter un protocole' : 'Modifier un protocole'}
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid-2_xs-1">
                  <div className="col">
                    <Field
                      initialValue={(editMode && protocole && protocole.nom) || ''}
                      validate={required}
                      name="nom"
                      component="input"
                      className="field"
                      parse={value => value}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Nom *</label>
                        </div>
                      )}
                    </Field>
                    <Field
                      validate={required}
                      initialValue={(protocole && protocole.type) || null}
                      name="type"
                      component="select"
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <Select
                              options={optionsTypes}
                              placeholder="Sélectionner un type"
                              onChange={option => {
                                input.onChange(option.value);
                              }}
                              value={find(optionsTypes, o => o.value === input.value)}
                            />
                            <label>Type *</label>
                          </div>
                        );
                      }}
                    </Field>
                    <Field name="droits_access" initialValue={editMode && protocole.droits_access}>
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}

                            <Select
                              onChange={valeurs => {
                                input.onChange(map(valeurs, v => v.value));
                              }}
                              placeholder="Sélectionner un/ou des types de profils"
                              isMulti
                              name="droits_access"
                              defaultValue={
                                (editMode &&
                                  map(protocole.droits_access, droit => {
                                    return {
                                      label: find(optionsProfil, o => o.value === droit).label,
                                      value: droit
                                    };
                                  })) ||
                                []
                              }
                              options={optionsProfil}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                            <label>Droit d’accès</label>
                          </div>
                        );
                      }}
                    </Field>

                    <Field
                      initialValue={(editMode && protocole && protocole.but) || ''}
                      validate={required}
                      name="but"
                      component="input"
                      className="field"
                      parse={value => value}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>But *</label>
                        </div>
                      )}
                    </Field>

                    <Field
                      initialValue={(editMode && protocole && protocole.nombre_operateurs) || ''}
                      validate={required}
                      name="nombre_operateurs"
                      component="input"
                      className="field"
                      parse={value => value}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Nombre opérateurs *</label>
                        </div>
                      )}
                    </Field>
                    <Field
                      validate={required}
                      initialValue={(protocole && protocole.photo) || optionsPhoto[0].value}
                      name="photo"
                      component="select"
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}
                            <Select
                              options={optionsPhoto}
                              placeholder="Sélectionner une option"
                              onChange={option => {
                                input.onChange(option.value);
                              }}
                              value={find(optionsPhoto, o => o.value === input.value)}
                            />
                            <label>Ajout de photo(s) *</label>
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div className="col">
                    <Field
                      initialValue={(editMode && protocole && protocole.quand) || ''}
                      validate={required}
                      name="quand"
                      component="input"
                      className="field"
                      parse={value => value}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Quand *</label>
                        </div>
                      )}
                    </Field>
                    <Field
                      initialValue={(editMode && protocole && protocole.duree) || ''}
                      validate={required}
                      name="duree"
                      component="input"
                      className="field"
                      parse={value => value}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Durée *</label>
                        </div>
                      )}
                    </Field>
                    <Field
                      initialValue={(editMode && protocole && protocole.outils) || ''}
                      validate={required}
                      name="outils"
                      component="input"
                      className="field"
                      parse={value => value}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <textarea {...input} rows="5" />
                          <label>Outils *</label>
                        </div>
                      )}
                    </Field>
                    <Field
                      initialValue={(editMode && protocole && protocole.terrain) || ''}
                      validate={required}
                      name="terrain"
                      component="input"
                      className="field"
                      parse={value => value}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" />
                          <label>Terrain *</label>
                        </div>
                      )}
                    </Field>
                    <Field
                      initialValue={
                        editMode
                          ? protocole && protocole.displayNumLigneQuestion === true
                            ? true
                            : false
                          : true
                      }
                      name="displayNumLigneQuestion"
                      component="select"
                    >
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            <Select
                              options={optionsBoolean}
                              onChange={option => {
                                input.onChange(option.value);
                              }}
                              value={find(optionsBoolean, o => o.value === input.value)}
                            />
                            <label>Affichage Numéro de repère/ligne *</label>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="modal-footer">
                  <div className="btn-group">
                    <button onClick={() => setIsOpen(false)} className="btn btn-secondary">
                      <span>Annuler</span>
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <span>Valider</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalFormProtocole.propTypes = {
  createProtocole: PropTypes.func.isRequired,
  updateProtocole: PropTypes.func.isRequired
};

export default connect(null, {
  createProtocole,
  updateProtocole
})(ModalFormProtocole);
