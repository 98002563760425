import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import TableListView from '../shared/TableListView';
import ModalFormAddProtocole from '../modals/ModalFormAddProtocole';
import ButtonConfirmModal from '../shared/ButtonConfirmModal';
import ModalSiteProtocoleHtmlEdit from '../modals/ModalSiteProtocoleHtmlEdit';

import { map, filter } from 'lodash';
import { TYPES_PROTOCOLES } from '../../constants/Properties';

const SiteProtocolesTableListView = ({ protocoles, site, onClickDelete }) => {
  const data = map(site.protocoles, p => {
    return {
      protocole_id: p.protocole_id,
      nom: protocoles[p.protocole_id].nom,
      type: TYPES_PROTOCOLES[protocoles[p.protocole_id].type]
    };
  });

  return (
    <TableListView
      buttons={() => <ModalFormAddProtocole protocolesProps={protocoles} site={site} />}
      className="table-wrapper"
      data={data}
      search={['nom', 'type']}
      sortByDefault="nom"
      searchLabel={'Rechercher'}
      sortOrderDefault="asc"
      cols={() => ({
        nom: { label: 'Nom' },
        type: {
          label: 'Type'
        }
      })}
      rowButtons={protocole => {
        return (
          <Fragment>
            {protocole && (
              <div className="btn-group">
                <ModalSiteProtocoleHtmlEdit site={site} protocoleId={protocole.protocole_id} />
                <ButtonConfirmModal
                  message="Voulez-vous vraiment supprimer ce protocole ?"
                  actionDelete={() =>
                    onClickDelete(
                      filter(site.protocoles, prot => prot.protocole_id !== protocole.protocole_id)
                    )
                  }
                />
              </div>
            )}
          </Fragment>
        );
      }}
    />
  );
};

SiteProtocolesTableListView.propTypes = {
  protocoles: PropTypes.object.isRequired,
  site: PropTypes.object
};

export default connect(state => ({
  protocoles: state.data.entities.protocoles
}))(SiteProtocolesTableListView);
