import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadProtocoles } from '../store/modules/protocoles';
import { loadUtilisateurs } from '../store/modules/utilisateurs';
import { deleteSite, loadSites } from '../store/modules/sites';

import BaseLayout from './shared/BaseLayout';
import SitesTableListView from '../components/tableaux/SitesTableListView';
import { loadObservations } from '../store/modules/observations';

const SitesPage = ({
  loadProtocoles,
  loadSites,
  loadObservations,
  loadUtilisateurs,
  deleteSite
}) => {
  useEffect(() => {
    loadSites();
    loadProtocoles();
    loadObservations();
    loadUtilisateurs();
  }, []);

  return (
    <BaseLayout>
      <div className="container">
        <div className="page-header">
          <div className="page-header-left">
            <div className="page-header-title">Sites</div>
          </div>
        </div>
        <SitesTableListView onClickDelete={siteId => deleteSite(siteId)} />
      </div>
    </BaseLayout>
  );
};

SitesPage.propTypes = {
  loadSites: PropTypes.func.isRequired,
  deleteSite: PropTypes.func.isRequired
};

export default connect(null, {
  loadSites,
  deleteSite,
  loadProtocoles,
  loadObservations,
  loadUtilisateurs
})(SitesPage);
