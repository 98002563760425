import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { deleteProtocole, loadProtocoles } from '../store/modules/protocoles';

import BaseLayout from './shared/BaseLayout';
import ProtocolesTableListView from '../components/tableaux/ProtocolesTableListView';

const ProtocolesPage = ({ loadProtocoles, deleteProtocole }) => {
  useEffect(() => {
    loadProtocoles();
  }, []);

  return (
    <BaseLayout>
      <div className="container">
        <div className="page-header">
          <div className="page-header-left">
            <div className="page-header-title">Protocoles</div>
          </div>
        </div>
        <ProtocolesTableListView onClickDelete={protocoleId => deleteProtocole(protocoleId)} />
      </div>
    </BaseLayout>
  );
};

ProtocolesPage.propTypes = {
  loadProtocoles: PropTypes.func.isRequired,
  deleteProtocole: PropTypes.func.isRequired
};

export default connect(null, {
  loadProtocoles,
  deleteProtocole
})(ProtocolesPage);
