import initialState from '../initialState';
import '../../index';

import { uploadsActionsHandlers } from './uploads';
import { settingsActionsHandlers } from './settings';
import { observationsActionsHandlers } from './observations';
import { sitesActionsHandlers } from './sites';
import { protocolesActionsHandlers } from './protocoles';
import { utilisateursActionsHandlers } from './utilisateurs';
import { translationsActionsHandlers } from './translations';

const actionsHandlers = {
  ...uploadsActionsHandlers,
  ...settingsActionsHandlers,
  ...observationsActionsHandlers,
  ...sitesActionsHandlers,
  ...protocolesActionsHandlers,
  ...utilisateursActionsHandlers,
  ...translationsActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
