import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseLayout from './shared/BaseLayout';

import HeaderTableau from '../components/shared/HeaderTableau';
import MesuresProtocoleTable from '../components/tableaux/MesuresProtocoleTable';

const ProtocolePage = ({ protocole }) => {
  return (
    <BaseLayout contentClass="column">
      <Fragment>
        <HeaderTableau name={protocole && protocole.nom} />
        <div className="content-tabs">
          <div className="container">
            <div className="toggle">
              <button className="btn active">Mesures</button>
            </div>
          </div>
        </div>
        <div className="container">
          <MesuresProtocoleTable protocole={protocole} />
        </div>
      </Fragment>
    </BaseLayout>
  );
};

ProtocolePage.propTypes = {
  protocole: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { protocoleId }
    }
  } = props;

  return {
    protocole: state.data.entities.protocoles && state.data.entities.protocoles[Number(protocoleId)]
  };
};
export default connect(mapStateToProps, null)(ProtocolePage);
