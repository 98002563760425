export const ROLES = {
  ADMIN: 'Administrateur',
  USER: 'Utilisateur'
};

export const TYPES_PROFILS = {
  NEOPHYTE: 'Débutant',
  HABITUE: 'Habitué',
  GESTIONNAIRE: 'Gestionnaire'
};

export const TYPES_PROTOCOLES = {
  EROSION: 'Erosion',
  SUBMERSION: 'Submersion'
};

export const TYPES_PHOTOS = {
  AUCUNE: 'Aucune photo',
  FACULTATIF: '1 photo facultative',
  OBLIGATOIRE: '1 photo obligatoire',
  PLUSIEURSFACULTATIF: 'Plusieurs photos facultatives',
  PLUSIEURS1OBLIGATOIRE: 'Plusieurs photos dont au minimum 1 photo obligatoire',
  PLUSIEURSOBLIGATOIREMIN10: 'Plusieurs photos obligatoires (minimum 10)'
};

export const TYPES_SAISIE = {
  DIRECTE: 'Directe',
  COMPARAISON: 'Comparaison',
  REPETITION: 'Repetition',
  TEXTE: 'Texte'
};

export const BADGES_EXPLORATEUR = {
  1: {
    labelType: 'Explorateur',
    labelBadge: 'Bronze',
    img: require('../../assets/images/EXPLORATEUR_BRONZE.svg')
  },
  2: {
    labelType: 'Explorateur',
    labelBadge: 'Argent',
    img: require('../../assets/images/EXPLORATEUR_ARGENT.svg')
  },
  2: {
    labelType: 'Explorateur',
    labelBadge: 'Or',
    img: require('../../assets/images/EXPLORATEUR_OR.svg')
  }
};

export const BADGES_FREQUENCE = {
  1: {
    labelType: 'Fréquence',
    labelBadge: 'Bronze',
    img: require('../../assets/images/FREQUENCE_BRONZE.svg')
  },
  2: {
    labelType: 'Fréquence',
    labelBadge: 'Argent',
    img: require('../../assets/images/FREQUENCE_ARGENT.svg')
  },
  3: {
    labelType: 'Fréquence',
    labelBadge: 'Or',
    img: require('../../assets/images/FREQUENCE_OR.svg')
  }
};

export const TYPES_ETALONS = {
  COEFFICIENT_PAS: 'Coefficient de pas',
  PERSONNE_ETALON: 'Personne étalon',
  INDEX_SUCCESSIFS: 'Index successifs'
};
