import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import SideBar from '../../components/shared/SideBar';
import HeaderBar from '../../components/shared/HeaderBar';

Modal.setAppElement('body');

const BaseLayout = ({ children, contentClass }) => {
  return (
    <Fragment>
      {process.env.NODE_ENV === 'staging' && (
        <div id="staging-banner">
          <i className="fas fa-exclamation-triangle" />
          Environnement de recette
          <i className="fas fa-exclamation-triangle" />
        </div>
      )}
      <div id="wrapper">
        <SideBar />
        <main>
          <HeaderBar />
          <section className={`content${contentClass ? ' ' + contentClass : ''}`}>
            {children}
          </section>
        </main>
      </div>
    </Fragment>
  );
};
BaseLayout.propTypes = {
  children: PropTypes.node
};

export default connect(state => ({
  account: state.auth.user
}))(BaseLayout);
