import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_TRANSLATIONS_REQUEST = 'elwa/translations/FETCH_ALL_TRANSLATIONS_REQUEST';
export const FETCH_ALL_TRANSLATIONS_SUCCESS = 'elwa/translations/FETCH_ALL_TRANSLATIONS_SUCCESS';
const FETCH_ALL_TRANSLATIONS_FAILURE = 'elwa/translations/FETCH_ALL_TRANSLATIONS_FAILURE';
const FETCH_TRANSLATION_REQUEST = 'elwa/translations/FETCH_TRANSLATION_REQUEST';
const FETCH_TRANSLATION_SUCCESS = 'elwa/translations/FETCH_TRANSLATION_SUCCESS';
const FETCH_TRANSLATION_FAILURE = 'elwa/translations/FETCH_TRANSLATION_FAILURE';
const SET_TRANSLATION_REQUEST = 'elwa/translations/SET_TRANSLATION_REQUEST';
const SET_TRANSLATION_SUCCESS = 'elwa/translations/SET_TRANSLATION_SUCCESS';
const SET_TRANSLATION_FAILURE = 'elwa/translations/SET_TRANSLATION_FAILURE';
const EDIT_TRANSLATION_REQUEST = 'elwa/translations/EDIT_TRANSLATION_REQUEST';
const EDIT_TRANSLATION_SUCCESS = 'elwa/translations/EDIT_TRANSLATION_SUCCESS';
const EDIT_TRANSLATION_FAILURE = 'elwa/translations/EDIT_TRANSLATION_FAILURE';
const DELETE_TRANSLATION_REQUEST = 'elwa/translations/DELETE_TRANSLATION_REQUEST';
const DELETE_TRANSLATION_SUCCESS = 'elwa/translations/DELETE_TRANSLATION_SUCCESS';
const DELETE_TRANSLATION_FAILURE = 'elwa/translations/DELETE_TRANSLATION_FAILURE';

export const translationsActionsHandlers = {
  [FETCH_ALL_TRANSLATIONS_REQUEST]: state =>
    flow(set('loaded.translations', false), set('loading.translations', true))(state),
  [FETCH_ALL_TRANSLATIONS_SUCCESS]: (state, action) =>
    flow(
      set('entities.translations', action.response.entities.translations || {}),
      set('loaded.translations', true),
      set('loading.translations', false)
    )(state),
  [FETCH_ALL_TRANSLATIONS_FAILURE]: state =>
    flow(set('loaded.translations', false), set('loading.translations', false))(state),
  [FETCH_TRANSLATION_SUCCESS]: (state, action) => {
    console.log('value of response : ', action.response);
    return flow(set(`entities.translations.${action.id}`, action.response.entities.translations))(
      state
    );
  },
  [SET_TRANSLATION_SUCCESS]: (state, action) =>
    flow(
      set('entities.translations', {
        ...state.entities.translations,
        ...action.response.entities.translations
      })
    )(state),

  [EDIT_TRANSLATION_SUCCESS]: (state, action) =>
    flow(
      set('entities.translations', {
        ...state.entities.translations,
        ...action.response.entities.translations
      })
    )(state),
  [DELETE_TRANSLATION_SUCCESS]: (state, action) =>
    flow(set('entities.translations', omit(state.entities.translations, action.id)))(state)
};

export function loadTranslation(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_TRANSLATION_REQUEST, FETCH_TRANSLATION_SUCCESS, FETCH_TRANSLATION_FAILURE],
      method: 'GET',
      endpoint: `/translations/${id}`,
      schema: Schemas.TRANSLATION
    }
  };
}

export function loadTranslations() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_TRANSLATIONS_REQUEST,
        FETCH_ALL_TRANSLATIONS_SUCCESS,
        FETCH_ALL_TRANSLATIONS_FAILURE
      ],
      method: 'GET',
      endpoint: '/translations',
      schema: Schemas.TRANSLATION_ARRAY,
      successMessage: 'Translations fetched successfully'
    }
  };
}

export function editTranslation(translation, id) {
  return {
    [CALL_API]: {
      types: [EDIT_TRANSLATION_REQUEST, EDIT_TRANSLATION_SUCCESS, EDIT_TRANSLATION_FAILURE],
      method: 'PUT',
      endpoint: `/translations/${id}`,
      schema: Schemas.TRANSLATION,
      body: translation,
      successMessage: 'La traduction a été mis à jour'
    }
  };
}

export function deleteTranslation(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_TRANSLATION_REQUEST, DELETE_TRANSLATION_SUCCESS, DELETE_TRANSLATION_FAILURE],
      method: 'DELETE',
      endpoint: `/translations/${id}`,
      successMessage: 'Translations deleted successfully'
    }
  };
}
