import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../store/modules/auth';
import { purge } from '../../store/modules/settings';

const HeaderBar = ({ user, logout, purge }) => {
  const deconnexion = () => {
    return Promise.resolve()
      .then(() => logout())
      .then(() => purge());
  };

  return (
    <header className="header">
      <div className="container">
        <div className="user-infos"></div>
        <div className="user-block">
          <div className="user-avatar-block">
            <i className="fal fa-user" />
          </div>
          <div className="user-profil">
            <span className="user-type">{/* {user && DISPLAY_ROLE[user.role]} */}</span>
            <span className="user-name">
              {user && user.nom} {user && user.prenom}
            </span>
          </div>
          <NavLink className="btn" to="/" onClick={() => deconnexion()}>
            <i className="far fa-sign-out" />
          </NavLink>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, { logout, purge })(HeaderBar);
